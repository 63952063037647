{{#if flags.ccmCarePlanVisitTemplate}}
{{> header val="HPI" }}
{{> patient_summary }}
{{> free_text_space }}

{{#ifContains programTypes "RPM"}}
{{> subheader val="Recent Vital Trends" }}
{{> vitals_list }}
<br/>
{{/ifContains}}

{{> subheader val="Care Plan Communication preference" }}
{{> free_text_space }}
{{/if}}
