import { useWizardStateContext } from '@/shared/common/Wizard/state';
import { useShouldMoveUpActionPlan } from '@/shared/common/Wizard/state/sectionUtils';

import { REGULAR_VISIT_SECTIONS } from '../../metadata';
import { SharedReviewActionSteps } from '../../shared/ReviewActionSteps';
import type { TActionStep } from '../../shared/SetActionSteps';
import { regVisitPath } from '../paths';
import { usePatientGoalText } from './usePatientGoal';

export function ReviewActionSteps() {
  const patientGoalText = usePatientGoalText();
  const {
    wizardFormData: { data: stepData },
  } = useWizardStateContext();
  const actionSteps = (stepData[
    regVisitPath('/action-plan', '/set-action-steps')
  ]?.values?.actionSteps || []) as TActionStep[];

  const shouldMoveUpActionPlan = useShouldMoveUpActionPlan();

  return (
    <SharedReviewActionSteps
      sections={REGULAR_VISIT_SECTIONS}
      nextPath={
        shouldMoveUpActionPlan
          ? regVisitPath('/med-review', '/index')
          : regVisitPath('/scheduling', '/index')
      }
      actionSteps={actionSteps}
      patientGoalText={patientGoalText}
    />
  );
}
