import { EHR } from '@/shared/types/ehr.types';

import { usePatientDetails, usePatientHospitals } from './queries';
import { useFlags } from './useFlags';

export function useCanPublishToEhr(patientId: string) {
  const { data: patient } = usePatientDetails(patientId, false);
  const ehr = patient?.ehr_information?.ehr;
  const { hidePublishToEhr, showPublishToEhr } = useFlags();
  const { data } = usePatientHospitals({
    patientId: patientId || '',
    pageSize: 1,
    page: 1,
  });
  const hospitalIds = data?.hospitals.map((hospital) => hospital?.id) || [];
  const isIntegratedEhr = ehr && ehr !== EHR.NON_INTEGRATED;
  const notAllowedHospital = hospitalIds.some((hospitalId) =>
    hidePublishToEhr.includes(hospitalId),
  );

  return Boolean(showPublishToEhr && isIntegratedEhr && !notAllowedHospital);
}
