import type { IntlShape } from 'react-intl';
import { useIntl } from 'react-intl';

import type { TitrationRecommendation } from '@/components/AsyncTitration/hooks';
import { isAsyncTitrationPending } from '@/components/AsyncTitration/statuses';
import type { MedicationChange } from '@/shared/generated/grpcGateway/medication.pb';
import {
  MedicationChangeChangeType,
  MedicationChangeStatus,
} from '@/shared/generated/grpcGateway/medication.pb';

import { medRowSubtext, previousDose } from './MedicationRow.css';
import {
  getMedChangeDailyDosage,
  getTitrationRecommendationDailyDosage,
} from './utils/medInfoUtils';

type Props = {
  medChange: MedicationChange;
  status?: MedicationChangeStatus;
  titrationRecommendation?: TitrationRecommendation;
};

export function MedicationSubtext({
  medChange,
  status,
  titrationRecommendation,
}: Props) {
  const intl = useIntl();
  const { reason, changeType } = medChange;

  const subtext = getSubtext(
    intl,
    reason,
    changeType,
    status,
    titrationRecommendation,
    medChange,
  );

  if (!subtext) {
    return null;
  }

  return <div className={medRowSubtext}>{subtext}</div>;
}

function getSubtext(
  intl: IntlShape,
  reason?: string,
  changeType?: MedicationChangeChangeType,
  status?: MedicationChangeStatus,
  titrationRecommendation?: TitrationRecommendation,
  medChange?: MedicationChange,
) {
  if (
    medChange &&
    titrationRecommendation?.rxNorm &&
    isAsyncTitrationPending(titrationRecommendation)
  ) {
    return intl.formatMessage(
      {
        defaultMessage:
          'Titration: {currentDailyDosage} → {recommendedDailyDosage}',
      },
      {
        currentDailyDosage: (
          <span className={previousDose}>
            {getMedChangeDailyDosage(medChange, intl)}
          </span>
        ),
        recommendedDailyDosage: getTitrationRecommendationDailyDosage(
          titrationRecommendation,
          intl,
        ),
      },
    );
  }

  switch (changeType) {
    case MedicationChangeChangeType.REVIEW:
      return reason;
      break;

    case MedicationChangeChangeType.STOP:
      return reason
        ? intl.formatMessage(
            { defaultMessage: 'Stopped- {reason}' },
            { reason },
          )
        : intl.formatMessage({ defaultMessage: 'Stopped' });

    case MedicationChangeChangeType.START:
      if (status === MedicationChangeStatus.INACTIVE) {
        return null;
      }
      return reason
        ? intl.formatMessage(
            { defaultMessage: 'Started- {reason}' },
            { reason },
          )
        : intl.formatMessage({ defaultMessage: 'Started' });

    case MedicationChangeChangeType.TITRATION:
      if (status === MedicationChangeStatus.INACTIVE) {
        return null;
      }
      return reason
        ? intl.formatMessage(
            { defaultMessage: 'Titrated- {reason}' },
            { reason },
          )
        : intl.formatMessage({ defaultMessage: 'Titrated' });
    default:
      return null;
  }

  return null;
}
