import type { AxiosError } from 'axios';
import first from 'lodash/first';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import type { QueryKey, UseQueryOptions } from 'react-query';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import type { QueryCallbacks } from '@/reactQuery/types';
import type {
  CCMCarePlan,
  CCMCarePlanNoteInfo,
  CCMCarePlanPdf,
  ListCCMCarePlansRequest,
  ListCCMCarePlansResponse,
  SaveCCMCarePlanRequest,
} from '@/shared/generated/grpcGateway/ccm_care_plan.pb';
import {
  CCMCarePlanService,
  State as CarePlanState,
} from '@/shared/generated/grpcGateway/ccm_care_plan.pb';
import { useToaster } from '@/shared/tempo/molecule/Toast';
import { grpcNameToId, idToGrpcName } from '@/shared/utils/grpc';

export const CARE_PLAN_QUERY_KEY_BASE = ['rpm', 'v1', 'carePlan'] as const;

export const carePlanKeys = {
  list: (params?: ListCCMCarePlansRequest) =>
    [...CARE_PLAN_QUERY_KEY_BASE, params] as const,
  detail: (id: string) => [...CARE_PLAN_QUERY_KEY_BASE, id] as const,
  detailWithNoteInfo: (id: string) =>
    [...CARE_PLAN_QUERY_KEY_BASE, id, 'care_plan_note_info'] as const,
  pdf: (id: string) => [...CARE_PLAN_QUERY_KEY_BASE, 'pdf', id] as const,
};

export function useListCarePlans(
  params: ListCCMCarePlansRequest,
  config?: UseQueryOptions<ListCCMCarePlansResponse>,
) {
  return useQuery<ListCCMCarePlansResponse>(
    carePlanKeys.list(params),
    () => CCMCarePlanService.ListCCMCarePlans(params),
    config,
  );
}

export function useCreateCarePlan(callbacks?: QueryCallbacks<CCMCarePlan>) {
  const client = useQueryClient();

  return useMutation(
    (ccmCarePlan: CCMCarePlan) =>
      CCMCarePlanService.CreateCCMCarePlan({ ccmCarePlan }),
    {
      onSuccess: async (data) => {
        await client.invalidateQueries(CARE_PLAN_QUERY_KEY_BASE);
        callbacks?.onSuccess?.(data);
      },
      onError: callbacks?.onError,
    },
  );
}

export function useSaveCarePlan(callbacks?: QueryCallbacks<CCMCarePlan>) {
  const client = useQueryClient();
  async function defaultSuccessCallback(data: CCMCarePlan) {
    await client.invalidateQueries(CARE_PLAN_QUERY_KEY_BASE);
    callbacks?.onSuccess?.(data);
  }
  const mutationResult = useMutation(
    (params: SaveCCMCarePlanRequest) =>
      CCMCarePlanService.SaveCCMCarePlan(params),
    {
      onSuccess: defaultSuccessCallback,
      onError: callbacks?.onError,
    },
  );

  return { ...mutationResult, defaultSuccessCallback };
}

export function useCarePlan(
  id: string,
  options: UseQueryOptions<CCMCarePlan> = {},
) {
  return useQuery(
    carePlanKeys.detail(id) as QueryKey,
    () =>
      CCMCarePlanService.GetCCMCarePlan({
        name: idToGrpcName('ccmCarePlan', id),
      }),
    options,
  );
}

export function useCarePlanNoteInfo(
  id: string,
  options: UseQueryOptions<CCMCarePlanNoteInfo> = {},
) {
  return useQuery(
    carePlanKeys.detailWithNoteInfo(id) as QueryKey,
    () =>
      CCMCarePlanService.GetCCMCarePlanNoteInfo({
        name: idToGrpcName('ccmCarePlanNoteInfo', id),
      }),
    options,
  );
}

export function useCarePlanDraft(
  patientId: string,
  options: UseQueryOptions<ListCCMCarePlansResponse> = {},
) {
  const { data: existingDraft, ...rest } = useListCarePlans(
    {
      filter: `patientId="${patientId}" AND state="${CarePlanState.DRAFT}"`,
    },
    options,
  );
  const draftPlan = first(existingDraft?.ccmCarePlans);

  return {
    data: draftPlan,
    ...rest,
  };
}

export function useCarePlanApproved(
  patientId: string,
  options: UseQueryOptions<ListCCMCarePlansResponse> = {},
) {
  const { data: existingDraft, ...rest } = useListCarePlans(
    {
      filter: `patientId="${patientId}" AND state="${CarePlanState.APPROVED}"`,
      orderBy: `createTime desc`,
    },
    options,
  );
  const approvedPlan = first(existingDraft?.ccmCarePlans);

  return {
    data: approvedPlan,
    ...rest,
  };
}

export function useDeleteCarePlan(callbacks?: QueryCallbacks<void>) {
  const client = useQueryClient();

  return useMutation(
    (name: string) =>
      CCMCarePlanService.DeleteCCMCarePlan({
        name,
      }),
    {
      onSuccess: async () => {
        callbacks?.onSuccess?.();
        await client.invalidateQueries(CARE_PLAN_QUERY_KEY_BASE);
      },
      onError: callbacks?.onError,
    },
  );
}

export function useApproveCarePlan(callbacks?: QueryCallbacks<void>) {
  const client = useQueryClient();

  return useMutation(
    ({ name, approvalDate }: { name: string; approvalDate: GoogleDateTime }) =>
      CCMCarePlanService.MarkCCMCarePlanApproved({
        name,
        approvalDate,
      }),
    {
      onSuccess: async () => {
        await client.invalidateQueries(CARE_PLAN_QUERY_KEY_BASE);
        callbacks?.onSuccess?.();
      },
      onError: callbacks?.onError,
    },
  );
}

export function useGenerateCarePlanPdf(callbacks?: QueryCallbacks<string>) {
  const queryClient = useQueryClient();
  return useMutation(
    ({ name }: { name: string }) =>
      CCMCarePlanService.GenerateCCMCarePlanPdf({ name }),
    {
      onSuccess: async (resp) => {
        if (resp.name) {
          queryClient.invalidateQueries(grpcNameToId(resp.name));
          callbacks?.onSuccess?.(resp.name);
        } else {
          callbacks?.onError?.(resp.error);
        }
      },
      onError: callbacks?.onError,
    },
  );
}

export function useGetCarePlanPdf(
  id: string,
  options: UseQueryOptions<CCMCarePlanPdf> = {},
) {
  return useQuery(
    carePlanKeys.pdf(id) as QueryKey,
    () =>
      CCMCarePlanService.GetCCMCarePlanPdf({
        name: idToGrpcName('ccmCarePlan', id),
      }),
    options,
  );
}

/**
 * Triggers a PDF generation, then polls for the PDF until it is available,
 * then downloads it.
 */
export function useDownloadCarePlanPdf() {
  const MAX_RETRIES = 8;
  const intl = useIntl();
  const { toaster } = useToaster();
  const [hasDownloaded, setHasDownloaded] = useState(false);
  const {
    mutate: generatePdf,
    isLoading: isGenerating,
    data: pdfGenOperation,
    error: genPdfError,
  } = useGenerateCarePlanPdf({
    onError() {
      toaster.error(
        intl.formatMessage({
          defaultMessage: 'Failed to generate Care Plan PDF',
        }),
      );
    },
  });

  const pdfName = pdfGenOperation?.name;
  const {
    data: pdfData,
    isLoading: isPollingPdf,
    isFetching: isFetchingPdf,
    error: getPdfError,
  } = useGetCarePlanPdf(pdfName ? grpcNameToId(pdfName) || '' : '', {
    enabled: !!pdfName,
    onSuccess({ pdfUri }) {
      if (pdfUri && !hasDownloaded) {
        window.open(pdfUri);
        setHasDownloaded(true);
      }
    },
    onError() {
      toaster.error(
        intl.formatMessage({
          defaultMessage: 'Failed to retrieve Care Plan PDF',
        }),
      );
    },
    retryDelay: (attempt) => Math.min(1000 * 2 ** attempt, 15000),
    retry(failureCount, error) {
      const PDF_NOT_READY_ERROR = 'operation not done';

      const err = error as AxiosError<{ code: number; message: string }>;
      const errMsg = err.response?.data?.message || '';
      if (failureCount === MAX_RETRIES) {
        toaster.error(
          intl.formatMessage({
            defaultMessage: 'Failed to retrieve Care Plan PDF',
          }),
        );
      }
      return (
        !pdfData &&
        failureCount < MAX_RETRIES &&
        errMsg.includes(PDF_NOT_READY_ERROR)
      );
    },
  });

  return {
    mutate: (name: string) => {
      setHasDownloaded(false);
      generatePdf({ name });
    },
    isLoading: isGenerating || isPollingPdf || isFetchingPdf,
    error: genPdfError || getPdfError,
    pdfData,
  };
}
