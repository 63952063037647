import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import {
  FieldGroup,
  Script,
} from '@/pages/patients/PatientProfile/CNNotesSidebarPanel/shared';
import { InlineMessage } from '@/pages/patients/PatientProfile/CNNotesSidebarPanel/shared/InlineMessage';
import { formatProviderForScript } from '@/pages/patients/PatientProfile/CNNotesSidebarPanel/shared/script.utils';
import { useRecentNoteFormData } from '@/pages/patients/PatientProfile/CNNotesSidebarPanel/shared/useRecentNoteFormData';
import { useTimeEstimate } from '@/pages/patients/PatientProfile/CNNotesSidebarPanel/shared/useTimeEstimate';
import { NotesFilterValue } from '@/pages/patients/patientDetails/ui/Notes/Notes.types';
import { usePatientNotes } from '@/pages/patients/patientDetails/ui/Notes/note.queries';
import FileIcon from '@/shared/assets/svgs/file.svg?react';
import { BaseField } from '@/shared/common/Form/fields/BaseField';
import { Wizard } from '@/shared/common/Wizard';
import { useWizardFormFromConfig } from '@/shared/common/Wizard/state';
import { useShowVitalsEducation } from '@/shared/common/Wizard/state/sectionUtils';
import { usePatientContext, usePatientDetails } from '@/shared/hooks/queries';
import { display } from '@/shared/jsStyle/utils.css';
import { getRpmConditionsFromProgramAndStatus } from '@/shared/patient/conditions.utils';
import { Button } from '@/shared/tempo/atom/Button';
import { NoteType } from '@/shared/types/note.types';

import { useCNFormContext } from '../../../CNFormContext';
import { initVisitPath } from '../../initialVisit/paths';
import { CHF_VISIT_SECTIONS } from '../../metadata';
import type { EducationModulesReviewFormFields } from '../../shared/EducationModulesReview';
import {
  ReviewedModules,
  getEducationModulesReviewFormConfig,
} from '../../shared/EducationModulesReview';
import { chfVisitPath } from '../paths';
import { recentNoteButton } from './EducationModulesReview.css';
import { RecentNoteModal } from './RecentNoteModal';

export function EducationModulesReview() {
  const intl = useIntl();
  const path = chfVisitPath('/action-plan', '/education-modules-review');
  const [recentNoteModalOpen, setRecentNoteModalOpen] = useState(false);
  const { isLoading: isLoadingFormData, data } =
    useRecentNoteFormData<EducationModulesReviewFormFields>([
      path,
      initVisitPath('/action-plan', '/education-modules-review'),
    ]);
  const form = useWizardFormFromConfig<EducationModulesReviewFormFields>(path, {
    ...getEducationModulesReviewFormConfig(intl, data),
    triggerReset: !isLoadingFormData,
  });
  const { patientId } = useCNFormContext();
  const { data: patientDetails } = usePatientDetails(
    patientId,
    false,
    Boolean(patientId),
  );
  const [reviewedModules, previousReviewedModules] = form.watch([
    'reviewedModules',
    'previousReviewedModules',
  ]);
  const conditions = getRpmConditionsFromProgramAndStatus(
    patientDetails?.programs,
    patientDetails?.status,
  );
  const { data: context, isLoading: isLoadingContext } =
    usePatientContext(patientId);
  const { items: notes, infiniteQuery } = usePatientNotes(patientId, {
    noteTypes: [NoteType.Standard],
    notesFilter: NotesFilterValue.NPVisits,
  });
  const mostRecentNpNote = notes[0];

  const showVitalsEducation = useShowVitalsEducation();

  return (
    <>
      {recentNoteModalOpen && (
        <RecentNoteModal
          note={mostRecentNpNote}
          onClose={() => setRecentNoteModalOpen(false)}
        />
      )}
      <Wizard.Step
        isLoading={
          isLoadingFormData || isLoadingContext || infiniteQuery.isLoading
        }
        sections={CHF_VISIT_SECTIONS}
        form={form}
        title={intl.formatMessage({ defaultMessage: 'Education' })}
        subtitle={useTimeEstimate(
          intl.formatMessage({ defaultMessage: '1-3 min' }),
        )}
      >
        <FieldGroup>
          {!showVitalsEducation && (
            <Script>
              <FormattedMessage defaultMessage="Thanks for reviewing all of that information with me. It helps to know if anything has changed since your last visit." />
            </Script>
          )}
          <Script>
            <FormattedMessage
              defaultMessage="For our next step in today's visit, I'd like to cover some education with you. These resources are designed to help support your health goals in addition to working with your Cadence nurse practitioner and {provider}."
              values={{
                provider: formatProviderForScript(
                  context?.primaryPhysicianGivenName,
                  context?.primaryPhysicianFamilyName,
                  context?.primaryPhysicianRole,
                ),
              }}
            />
          </Script>
          {mostRecentNpNote && (
            <Button
              variant="secondary"
              className={recentNoteButton}
              onPress={() => setRecentNoteModalOpen(true)}
            >
              <Button.Icon>
                <FileIcon />
              </Button.Icon>
              <FormattedMessage defaultMessage="Review last NP note" />
            </Button>
          )}
          <InlineMessage>
            <FormattedMessage defaultMessage="Select the appropriate education modules and review with the patient. Afterwards, return to this screen and mark which modules were reviewed." />
          </InlineMessage>
          <ReviewedModules
            name="reviewedModules"
            conditions={conditions}
            reviewedModules={reviewedModules}
            previouslyReviewedModules={previousReviewedModules || []}
          />
          <BaseField
            name="previousReviewedModules"
            className={display.none}
            aria-label={intl.formatMessage({
              defaultMessage: 'Previously Reviewed Modules',
            })}
          />
        </FieldGroup>
      </Wizard.Step>
    </>
  );
}
