import type { ComponentType } from 'react';

import { TypeOfEncounter } from '@/pages/patients/patientDetails/ui/Notes/Notes.types';

import type { CNVisitType } from '../types';
import { CHF_VISIT_ROUTES } from './chfVisit/routes';
import { CYCLE_17_INIT_VISIT_ROUTES } from './cycle17InitialVisit/routes';
import { INIT_VISIT_ROUTES } from './initialVisit/routes';
import { PATIENT_FOLLOW_UP_ROUTES } from './patientFollowUp/routes';
import { REGULAR_VISIT_ROUTES } from './regularVisit/routes';

export const ROUTES_MAP: Record<
  CNVisitType,
  // `any` is fine here because we don't care about the component props
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Record<string, ComponentType<any>>
> = {
  [TypeOfEncounter.INITIAL_CN_VISIT]: INIT_VISIT_ROUTES,
  [TypeOfEncounter.CN_VISIT]: REGULAR_VISIT_ROUTES,
  [TypeOfEncounter.INITIAL_CN_CHF_VISIT]: CHF_VISIT_ROUTES,
  [TypeOfEncounter.CN_CHF_VISIT]: CHF_VISIT_ROUTES,
  [TypeOfEncounter.PATIENT_FOLLOW_UP]: PATIENT_FOLLOW_UP_ROUTES,
  [TypeOfEncounter.CYCLE_17_INITIAL_CN_VISIT]: CYCLE_17_INIT_VISIT_ROUTES,
};
