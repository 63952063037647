import type { ReactNode } from 'react';

import CloseCircle from '@/shared/assets/svgs/closeCircle.svg?react';
import { Form } from '@/shared/common/Form';
import { IconButton } from '@/shared/tempo/atom/IconButton';
import { color } from '@/shared/tempo/theme';

import { removeButton } from './RemovableRow.css';

type Props = {
  children: ReactNode;
  hasError: boolean;
  enabled?: boolean;
  onRemove: () => void;
};

export function RemovableRow({
  children,
  hasError,
  enabled = true,
  onRemove,
}: Props) {
  if (!enabled) {
    return <Form.Row>{children}</Form.Row>;
  }

  return (
    <Form.Row>
      {children}
      <IconButton
        variant="tertiary"
        size="small"
        onPress={onRemove}
        className={hasError ? removeButton.error : removeButton.default}
      >
        <CloseCircle fill={color.Theme.Light.Danger} />
      </IconButton>
    </Form.Row>
  );
}
