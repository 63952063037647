import classNames from 'classnames';

import { LoadingPlaceholder } from '@/shared/common/LoadingPlaceholder';
import type { PatientDetails } from '@/shared/generated/grpcGateway/pms.pb';
import { usePatientHospitals } from '@/shared/hooks/queries';
import type { Patient } from '@/shared/types/patient.types';

import {
  BasicPatientInfoFieldsV1,
  BasicPatientInfoFieldsV2,
} from './BasicPatientInfoFields';
import {
  loadingContainer,
  patientInfoContainer,
} from './PatientInfoSummary.css';
import { PatientInfoTagsWrapper } from './PatientInfoTags';
import { MedDelegationNoteInfoFieldV2 } from './PreferencesInfoFields';
import { SkeletonInfoSummary } from './SkeletonInfoSummary';

interface PatientInfoSummaryProps {
  patientDetails?: Patient;
}

interface PatientInfoSummaryPropsV2 {
  patientDetails?: PatientDetails;
  patientId: string;
}

export const PatientInfoSummary = ({
  patientDetails,
  patientId,
}: PatientInfoSummaryPropsV2) => (
  <div>
    {patientDetails && (
      <BasicPatientInfoFieldsV2
        patientDetails={patientDetails}
        patientId={patientId}
      />
    )}
    <PatientInfoTagsWrapper patientId={patientId} />

    <MedDelegationNoteInfoFieldV2 patientId={patientId} />
  </div>
);

export const BasicInfoPatientInfoSummary = ({
  patientDetails,
}: PatientInfoSummaryProps) => {
  const { id } = patientDetails || {};
  const { data, isLoading } = usePatientHospitals({ patientId: id || '' });

  return (
    <div className={classNames(patientInfoContainer)}>
      <LoadingPlaceholder
        className={loadingContainer}
        isLoading={isLoading}
        placeholder={<SkeletonInfoSummary />}
      >
        {patientDetails && (
          <BasicPatientInfoFieldsV1
            patientDetails={patientDetails}
            hospitals={data?.hospitals}
            showMedManagement
          />
        )}
      </LoadingPlaceholder>
    </div>
  );
};
