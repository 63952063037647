import { useIntl } from 'react-intl';

import { AsyncTitrationMedicationWasNotTitratedReason as Reason } from '@/shared/generated/grpcGateway/medication.pb';

export function useRejectionReasonI18n(): Record<Reason, string> {
  const intl = useIntl();

  return {
    [Reason.PROVIDER_PREFERENCE]: intl.formatMessage({
      defaultMessage: 'Provider Preference',
    }),
    [Reason.PATIENT_PREFERENCES]: intl.formatMessage({
      defaultMessage: 'Patient preference',
    }),
    [Reason.MEDICAL_REASON]: intl.formatMessage({
      defaultMessage: 'Medical reason',
    }),
    [Reason.FINANCIAL_REASON]: intl.formatMessage({
      defaultMessage: 'Financial reason',
    }),
    [Reason.NEW_PRESCRIPTION_REQUIRED]: intl.formatMessage({
      defaultMessage: 'New prescription required',
    }),
    [Reason.MEDICATION_NOT_ACCURATE]: intl.formatMessage({
      defaultMessage: 'Medication not accurate',
    }),
    [Reason.MEDICATION_WAS_NOT_TITRATED_REASON_UNSPECIFIED]: intl.formatMessage(
      { defaultMessage: 'Unspecified' },
    ),
    [Reason.AGE_OR_CONDITION]: intl.formatMessage({
      defaultMessage: 'Age/Condition',
    }),
    [Reason.CALCIUM_TOO_HIGH]: intl.formatMessage({
      defaultMessage: 'Calcium too high',
    }),
    [Reason.CALCIUM_TOO_LOW]: intl.formatMessage({
      defaultMessage: 'Calcium too low',
    }),
    [Reason.HEART_RATE_TOO_LOW]: intl.formatMessage({
      defaultMessage: 'Heart rate too low',
    }),
    [Reason.KIDNEY_FUNCTION_TOO_LOW]: intl.formatMessage({
      defaultMessage: 'Kidney function too low',
    }),
    [Reason.MANAGED_BY_EXTERNAL_PROVIDER]: intl.formatMessage({
      defaultMessage: 'Managed by Cardiology/Nephrology',
    }),
    [Reason.POTASSIUM_TOO_LOW]: intl.formatMessage({
      defaultMessage: 'Potassium too low',
    }),
    [Reason.POTASSIUM_TOO_HIGH]: intl.formatMessage({
      defaultMessage: 'Potassium too high',
    }),
    [Reason.RECENT_MEDICATION_CHANGE_BY_EXTERNAL_PROVIDER]: intl.formatMessage({
      defaultMessage:
        'Recent medication change made by PCP/Cardiology/Nephrology',
    }),
    [Reason.SIDE_EFFECT_AT_HIGHER_DOSE]: intl.formatMessage({
      defaultMessage: 'Side effect at higher dose',
    }),
    [Reason.SODIUM_TOO_LOW]: intl.formatMessage({
      defaultMessage: 'Sodium too low',
    }),
  };
}
