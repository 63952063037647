import { parseISO } from 'date-fns';

import type { RPMOrder } from '@/shared/generated/grpcGateway/pms.pb';

export function getCodesFromRPMOrder(order: RPMOrder): Nullable<string> {
  if (!order.icd10Codes) {
    return null;
  }
  try {
    return JSON.parse(order.icd10Codes).codes.join(', ');
  } catch {
    return null;
  }
}

export function sortOrders(orders: RPMOrder[]): RPMOrder[] {
  return [...orders].sort((a, b) => {
    if (!a.ehrCreatedAt) {
      return 0;
    }
    if (!b.ehrCreatedAt) {
      return 1;
    }
    return (
      parseISO(a.ehrCreatedAt).getDate() - parseISO(b.ehrCreatedAt).getDate()
    );
  });
}
