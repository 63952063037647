import { FormattedMessage } from 'react-intl';

import type { TitrationRecommendation } from '../hooks';
import { isAsyncTitrationPending } from '../statuses';

type Props = {
  readOnly: boolean;
  recommendation: TitrationRecommendation;
};

export function TitrationTagText({ readOnly, recommendation }: Props) {
  if (readOnly) {
    return <FormattedMessage defaultMessage="Titration suggestion" />;
  }

  if (isAsyncTitrationPending(recommendation)) {
    return <FormattedMessage defaultMessage="Titration pending" />;
  }

  return <FormattedMessage defaultMessage="Titration decision" />;
}
