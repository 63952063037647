import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory, useRouteMatch } from 'react-router-dom';

import TrashIcon from '@/shared/assets/svgs/trash.svg?react';
import { Form } from '@/shared/common/Form';
import { useFormFromConfig } from '@/shared/common/Form/FormContainer';
import { LoadingPlaceholder } from '@/shared/common/LoadingPlaceholder';
import { useFlags } from '@/shared/hooks';
import {
  useCarePlanDraft,
  useCarePlanNoteInfo,
} from '@/shared/hooks/queries/carePlan.queries';
import { Button } from '@/shared/tempo/atom/Button';
import { color } from '@/shared/tempo/theme';
import { NoteStatus } from '@/shared/types/note.types';
import { grpcNameToId } from '@/shared/utils/grpc';
import { parentPath } from '@/shared/utils/route-helpers';

import { DeleteModal } from '../DeleteModal';
import { ContextualGoalButton } from '../Goals/ContextualGoalButton';
import { CreateGoalModal } from '../Goals/CreateGoalModal';
import { EditGoalModal } from '../Goals/EditGoalModal';
import { GoalsGrid } from '../Goals/GoalsGrid';
import { type GoalModalState, GoalModalType } from '../Goals/goalModal.types';
import { GoalMeasure } from '../Goals/goals.types';
import { actions, formWrapper, gridContainer } from './CarePlanForm.css';
import { marshalCarePlanToFormData } from './dataTransformation';
import { getFormConfig } from './formConfig';
import { useAutosaveCarePlan, useOnSave } from './persistence';
import { EmergencyVisits } from './sections/EmergencyVisits';
import { GoalsAndInterventions } from './sections/GoalsAndInterventions';
import { MedicationAllergies } from './sections/MedicationAllergies';
import { Medications } from './sections/Medications';
import { PastSurgicalHistory } from './sections/PastSurgicalHistory';
import { PreventativeCare } from './sections/PreventativeCare';
import { PreventativeCareGaps } from './sections/PreventativeCareGaps';
import { ProblemList } from './sections/ProblemList';
import { PsychosocialAssessment } from './sections/PsychosocialAssessment';
import { Specialists } from './sections/Specialists';
import {
  goalSectionContainer,
  sectionTitle,
} from './sections/carePlanSections.css';

enum ModalType {
  Delete = 'delete',
}

type ModalState =
  | { type: ModalType.Delete; carePlanName: string }
  | GoalModalState;

export function CarePlanForm() {
  const intl = useIntl();
  const history = useHistory();
  const { ccmGoals } = useFlags();
  const [openedModal, setOpenedModal] = useState<Nullable<ModalState>>(null);
  const { url, params } = useRouteMatch<{ patientId: string }>();

  function navigateUp() {
    history.push(parentPath(url));
  }

  const {
    data: existingDraft,
    isLoading: isLoadingDraft,
    isFetching: isFetchingDraft,
  } = useCarePlanDraft(params.patientId, {
    onSuccess(data) {
      if (!data.ccmCarePlans?.length) {
        // No draft so navigate up
        navigateUp();
      }
    },
  });
  const draftPlan = existingDraft?.carePlan;
  const hasDraftPlan = !!draftPlan;
  const noteId = hasDraftPlan ? (existingDraft.noteId as number) : undefined;

  const form = useFormFromConfig({
    ...getFormConfig(
      intl,
      hasDraftPlan ? marshalCarePlanToFormData(draftPlan) : {},
    ),
    triggerReset: !isLoadingDraft && hasDraftPlan,
  });

  const { onSave, isSaving } = useOnSave(params.patientId, noteId);
  useAutosaveCarePlan(form, onSave, { enabled: !isFetchingDraft });

  const draftCarePlanId = existingDraft?.name
    ? grpcNameToId(existingDraft.name)
    : undefined;
  const {
    data: noteInfo,
    isLoading: isLoadingNoteInfo,
    isFetching: isFetchingNoteInfo,
  } = useCarePlanNoteInfo(draftCarePlanId || '', {
    enabled: Boolean(draftCarePlanId),
  });

  const associatedNoteIsPublished =
    noteInfo?.noteStatus === NoteStatus.Published;

  if (isLoadingDraft || !noteId) {
    return <LoadingPlaceholder isLoading />;
  }

  function createGoalWithMeasure(measure: GoalMeasure) {
    setOpenedModal({
      type: GoalModalType.CreateGoal,
      defaults: { measure },
    });
  }

  return (
    <>
      {ccmGoals && (
        <div className={gridContainer}>
          <GoalsGrid
            patientId={params.patientId}
            onEditGoal={(goal) =>
              setOpenedModal({ type: GoalModalType.EditGoal, goal })
            }
            onCreateGoal={() =>
              setOpenedModal({ type: GoalModalType.CreateGoal })
            }
          />
        </div>
      )}
      <div className={formWrapper}>
        <Form
          form={form}
          onSubmit={(data) => {
            onSave(data, {
              onSuccess: () => navigateUp(),
            });
          }}
        >
          {({ canSubmit }) => (
            <>
              <ProblemList />
              <PastSurgicalHistory form={form} />
              <Specialists form={form} />
              <EmergencyVisits
                form={form}
                createGoalWithMeasure={createGoalWithMeasure}
              />
              <Medications
                noteId={noteId}
                createGoalWithMeasure={createGoalWithMeasure}
              />
              <MedicationAllergies form={form} />
              <PreventativeCare
                form={form}
                createGoalWithMeasure={createGoalWithMeasure}
              />
              <Form.Section
                title={
                  <div className={goalSectionContainer}>
                    {intl.formatMessage({ defaultMessage: 'Social history' })}
                    <ContextualGoalButton
                      onPress={() => createGoalWithMeasure(GoalMeasure.Tobacco)}
                    />
                  </div>
                }
                classes={{ title: sectionTitle }}
              >
                <Form.Row>
                  <Form.TextField
                    size={11}
                    label={
                      <FormattedMessage defaultMessage="History of tobacco use?" />
                    }
                    name="tobaccoUse"
                  />
                </Form.Row>
              </Form.Section>
              <Form.Section
                title={
                  <div className={goalSectionContainer}>
                    {intl.formatMessage({ defaultMessage: 'Diet' })}
                    <ContextualGoalButton
                      onPress={() => createGoalWithMeasure(GoalMeasure.Diet)}
                    />
                  </div>
                }
                classes={{ title: sectionTitle }}
              >
                <Form.Row>
                  <Form.TextField
                    size={11}
                    label={
                      <FormattedMessage defaultMessage="How many times per week do you eat fast food meals?" />
                    }
                    name="fastFood"
                  />
                </Form.Row>
                <Form.Row>
                  <Form.TextField
                    size={11}
                    label={
                      <FormattedMessage defaultMessage="Do you snack often?" />
                    }
                    name="snacks"
                  />
                </Form.Row>
                <Form.Row>
                  <Form.TextField
                    size={11}
                    label={
                      <FormattedMessage defaultMessage="How many times per week do you eat desserts or other sweets?" />
                    }
                    name="desserts"
                  />
                </Form.Row>
                <Form.Row>
                  <Form.TextField
                    size={11}
                    label={
                      <FormattedMessage defaultMessage="Do you drink regular soda?" />
                    }
                    name="regularSoda"
                  />
                </Form.Row>
                <Form.Row>
                  <Form.TextField
                    size={11}
                    label={
                      <FormattedMessage defaultMessage="Do you drink sweet tea?" />
                    }
                    name="sweetTea"
                  />
                </Form.Row>
                <Form.Row>
                  <Form.TextField
                    size={11}
                    label={
                      <FormattedMessage defaultMessage="How much water do you drink per day?" />
                    }
                    name="waterIntake"
                  />
                </Form.Row>
                <Form.Row>
                  <Form.TextField
                    size={11}
                    label={
                      <FormattedMessage defaultMessage="Do you add salt to meals regularly?" />
                    }
                    name="saltIntake"
                  />
                </Form.Row>
              </Form.Section>
              <Form.Section
                title={
                  <div className={goalSectionContainer}>
                    {intl.formatMessage({ defaultMessage: 'Exercise' })}
                    <ContextualGoalButton
                      onPress={() =>
                        createGoalWithMeasure(GoalMeasure.Exercise)
                      }
                    />
                  </div>
                }
                classes={{ title: sectionTitle }}
              >
                <Form.Row>
                  <Form.TextArea
                    size={11}
                    rows={3}
                    label={
                      <FormattedMessage defaultMessage="Do you exercise regularly? If so, what type of exercise? How long and how often?" />
                    }
                    name="exercise"
                  />
                </Form.Row>
              </Form.Section>
              <PsychosocialAssessment
                form={form}
                createGoalWithMeasure={createGoalWithMeasure}
              />
              <GoalsAndInterventions
                form={form}
                createGoalWithMeasure={createGoalWithMeasure}
              />
              <PreventativeCareGaps form={form} />
              <Form.Section
                title={intl.formatMessage({ defaultMessage: 'Follow up' })}
                classes={{ title: sectionTitle }}
              >
                <Form.Row>
                  <Form.TextField
                    size={11}
                    label={
                      <FormattedMessage defaultMessage="Date and time for next follow-up with my chronic care manager" />
                    }
                    name="ccmAppointment"
                  />
                </Form.Row>
                <Form.Row>
                  <Form.TextField
                    size={11}
                    label={
                      <FormattedMessage defaultMessage="Date and time for next appointment with my doctor" />
                    }
                    name="providerAppointment"
                  />
                </Form.Row>
              </Form.Section>
              <Form.GridItem size={11} className={actions.container}>
                {!associatedNoteIsPublished ? (
                  <Button
                    variant="tertiary"
                    isDisabled={isLoadingNoteInfo || isFetchingNoteInfo}
                    className={actions.deleteButton}
                    onPress={() => {
                      if (existingDraft?.name) {
                        setOpenedModal({
                          type: ModalType.Delete,
                          carePlanName: existingDraft.name,
                        });
                      } else {
                        // if we don't have a name we haven't saved yet so we can just navigate out
                        navigateUp();
                      }
                    }}
                  >
                    <Button.Icon>
                      <TrashIcon fill={color.Theme.Light.Danger} />
                    </Button.Icon>
                    <FormattedMessage defaultMessage="Delete" />
                  </Button>
                ) : (
                  // Empty Div to keep Save button on the right
                  <div />
                )}
                <Button
                  type="submit"
                  isDisabled={!canSubmit}
                  isProcessing={isSaving}
                >
                  <FormattedMessage defaultMessage="Save Draft" />
                </Button>
              </Form.GridItem>
            </>
          )}
        </Form>
      </div>
      {openedModal?.type === GoalModalType.CreateGoal && (
        <CreateGoalModal
          patientId={params.patientId}
          carePlanId={
            existingDraft?.name ? grpcNameToId(existingDraft?.name) : undefined
          }
          defaults={openedModal.defaults}
          onClose={() => setOpenedModal(null)}
        />
      )}
      {openedModal?.type === GoalModalType.EditGoal && (
        <EditGoalModal
          goal={openedModal.goal}
          onClose={() => setOpenedModal(null)}
        />
      )}
      {openedModal?.type === ModalType.Delete && (
        <DeleteModal
          carePlanName={openedModal.carePlanName}
          onClose={() => setOpenedModal(null)}
          onDeleteSuccess={() => {
            setOpenedModal(null);
            navigateUp();
          }}
        />
      )}
    </>
  );
}
