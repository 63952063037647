import { Route, Switch } from 'react-router-dom';

import { ROUTES_MAP } from '@/pages/patients/PatientProfile/CNNotesSidebarPanel/CNWizardForm/sections/routesMap';
import type { CNVisitType } from '@/pages/patients/PatientProfile/CNNotesSidebarPanel/CNWizardForm/types';

import { NavigationSubscriber } from './NavigationSubscriber';
import { RouteNotFound } from './RouteNotFound';
import { Wizard as RootWizard } from './index';
import { useWizardStateContext } from './state';
import { useDisplayedSections } from './state/sectionUtils';

type Props = {
  visitType: CNVisitType;
};

export function Wizard({ visitType }: Props) {
  const { onNavigate } = useWizardStateContext();

  const displayedSections = useDisplayedSections();

  const routes = ROUTES_MAP[visitType];

  return (
    <>
      <RootWizard.ProgressBar sections={displayedSections} />
      <NavigationSubscriber onNavigation={onNavigate} />
      <Switch>
        {displayedSections.map((section) =>
          section.steps.map((step) => {
            const fullPath = `${section.basePath}${step.path}`;
            return (
              <Route
                exact
                key={fullPath}
                path={fullPath}
                component={routes[fullPath]}
              />
            );
          }),
        )}
        <Route path="*" component={RouteNotFound} />
      </Switch>
    </>
  );
}
