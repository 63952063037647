import cx from 'classnames';
import { type ReactNode, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { InitiallyReviewedActions } from '@/components/AsyncTitration/SuggestedTitration/Actions/InitiallyReviewedActions';
import { useReferenceMedication } from '@/pages/patients/PatientMedications/referenceMedications.queries';
import {
  AsyncTitrationAsyncTitrationStatus,
  type AsyncTitrationMedicationWasNotTitratedReason,
  AsyncTitrationAsyncTitrationStatus as AsyncTitrationStatus,
  type MedicationChange,
  type MedicationChangeFrequency,
} from '@/shared/generated/grpcGateway/medication.pb';
import { NoteStatus } from '@/shared/generated/grpcGateway/note.pb';
import { useUpdateAsyncTitration } from '@/shared/hooks/queries/medication.queries';
import { Tag } from '@/shared/tempo/atom/Tag';
import { useToaster } from '@/shared/tempo/molecule/Toast';

import type { TitrationRecommendation } from '../hooks/useTitrationRecommendation';
import { isAsyncTitrationPending } from '../statuses';
import { NewActions, UndoAction } from './Actions';
import { ApprovingProviderDetails } from './ApprovingProviderDetails';
import { MedicationDetails } from './MedicationDetails';
import { NotTitratingReasonSelect } from './NotTitratingReasonSelect';
import { Protocols } from './Protocols';
import {
  actions,
  container,
  notTitrating,
  protocols,
  tag,
} from './SuggestedTitration.css';
import { TitrationTagText } from './TitrationTagText';
import { SuggestedTitrationMode } from './types';

type Props = {
  children: ReactNode;
  titrationRecommendation: TitrationRecommendation;
  readOnly: boolean;
  onEdit?: (proposedChange: MedicationChange) => void;
  onCancelEdit?: () => void;
  isEditing?: boolean;
  noteId?: Maybe<number>;
  referenceMedicationId?: string;
};

export function SuggestedTitration({
  children,
  titrationRecommendation,
  noteId,
  onEdit,
  onCancelEdit,
  isEditing,
  readOnly,
  referenceMedicationId,
}: Props) {
  const [mode, setMode] = useState(SuggestedTitrationMode.Default);
  const { toaster } = useToaster();
  const { data: referenceMed } = useReferenceMedication(
    referenceMedicationId ?? '',
    !!referenceMedicationId,
  );
  const { mutate: mutateRecommendation, isLoading: isSaving } =
    useUpdateAsyncTitration(
      titrationRecommendation.patientId ?? '',
      titrationRecommendation.id ?? '',
      {
        onSettled: () => {
          // practically speaking, this doesn't matter for success since the
          // titration recommendation will be in a state where the mode doesn't
          // have any effect on what we're displaying to the user. that said,
          // i think it's better to make sure we're in a default state
          setMode(SuggestedTitrationMode.Default);
        },
        onError: () => {
          toaster.error(
            <FormattedMessage defaultMessage="Failed to update titration recommendation." />,
          );
        },
      },
    );

  const recommendedRxNorm = titrationRecommendation.rxNorm;

  if (!recommendedRxNorm) {
    return <>{children}</>;
  }

  const toggleMode = (newMode: SuggestedTitrationMode) => {
    if (readOnly) {
      return;
    }

    setMode((currentMode) =>
      currentMode === newMode ? SuggestedTitrationMode.Default : newMode,
    );
    onCancelEdit?.();
  };

  const updateRecommendation = (
    status: AsyncTitrationAsyncTitrationStatus,
    reason?: AsyncTitrationMedicationWasNotTitratedReason,
  ) => {
    mutateRecommendation({
      status,
      ...(status === AsyncTitrationStatus.INITIALLY_REVIEWED && {
        editedFrequency: titrationRecommendation.frequency,
        editedDoseQuantity: titrationRecommendation.doseQuantity,
        editedRxcui: titrationRecommendation.rxcui,
        initialReviewNoteId: noteId ?? 0,
      }),
      ...(status === AsyncTitrationStatus.REJECTED_ON_INITIAL_REVIEW && {
        medicationWasNotTitratedReason: reason,
        initialReviewNoteId: noteId ?? 0,
      }),
      ...([
        AsyncTitrationStatus.PATIENT_CONSENTED,
        AsyncTitrationStatus.PATIENT_REJECTED,
      ].includes(status) && {
        consentRequestNoteId: noteId ?? 0,
      }),
    });
  };
  const revertRecommendationChange = () => {
    const currentStatus = titrationRecommendation.status as NonNullable<
      typeof titrationRecommendation.status
    >;
    mutateRecommendation({
      ...([
        AsyncTitrationAsyncTitrationStatus.INITIALLY_REVIEWED,
        AsyncTitrationAsyncTitrationStatus.REJECTED_ON_INITIAL_REVIEW,
      ].includes(currentStatus) && {
        status: AsyncTitrationAsyncTitrationStatus.NEW,
        initialReviewNoteId: undefined,
        consentRequestNoteId: undefined,
        prescriptionChangeNoteId: undefined,
        editedRxcui: undefined,
        editedDoseQuantity: undefined,
        editedFrequency: undefined,
        medicationWasNotTitratedReason: undefined,
        editedNote: undefined,
      }),
      ...([
        AsyncTitrationAsyncTitrationStatus.PATIENT_CONSENTED,
        AsyncTitrationAsyncTitrationStatus.PATIENT_REJECTED,
      ].includes(currentStatus) && {
        status: AsyncTitrationAsyncTitrationStatus.INITIALLY_REVIEWED,
        consentRequestNoteId: undefined,
        prescriptionChangeNoteId: undefined,
      }),
      ...([
        AsyncTitrationAsyncTitrationStatus.APPLIED,
        AsyncTitrationAsyncTitrationStatus.REJECTED_ON_FINAL_REVIEW,
      ].includes(currentStatus) && {
        status: AsyncTitrationAsyncTitrationStatus.PATIENT_CONSENTED,
        prescriptionChangeNoteId: undefined,
      }),
    });
  };

  const onInitialReviewed = () => {
    setMode(SuggestedTitrationMode.Accepted);
    updateRecommendation(AsyncTitrationStatus.INITIALLY_REVIEWED);
  };
  const onRejectedOnInitialReview = (
    reason: AsyncTitrationMedicationWasNotTitratedReason,
  ) => {
    updateRecommendation(
      AsyncTitrationStatus.REJECTED_ON_INITIAL_REVIEW,
      reason,
    );
  };
  const onUndoInitialReview = () => {
    revertRecommendationChange();
  };

  const onPatientConsented = () => {
    updateRecommendation(AsyncTitrationStatus.PATIENT_CONSENTED);
  };
  const onPatientRejected = () => {
    updateRecommendation(AsyncTitrationStatus.PATIENT_REJECTED);
  };
  const onUndoPatientDecision = () => {
    revertRecommendationChange();
  };

  const {
    status: recommendationStatus,
    approvingCareProvider,
    approvingCareProviderId,
    initialApprovedAt,
    patientId,
  } = titrationRecommendation;
  const isInitialReviewNotePublished =
    titrationRecommendation.initialReviewNoteStatus === NoteStatus.PUBLISHED;

  return (
    <div className={container}>
      <div>
        <Tag
          variant={
            isAsyncTitrationPending(titrationRecommendation)
              ? 'default'
              : 'info'
          }
          className={tag.decision}
        >
          <TitrationTagText
            readOnly={readOnly}
            recommendation={titrationRecommendation}
          />
        </Tag>
        {children}
      </div>
      {!isEditing && (
        <>
          {recommendationStatus === AsyncTitrationStatus.NEW && (
            <div
              className={cx(protocols.wrapper, {
                [protocols.wrapperBorder]: !readOnly,
              })}
            >
              <Protocols
                titrationRecommendation={titrationRecommendation}
                recommendedRxNorm={recommendedRxNorm}
              />
            </div>
          )}
          {approvingCareProvider &&
            initialApprovedAt &&
            recommendationStatus !==
              AsyncTitrationStatus.REJECTED_ON_INITIAL_REVIEW && (
              <ApprovingProviderDetails
                patientId={patientId}
                providerId={approvingCareProviderId}
                provider={approvingCareProvider}
                approvalDate={initialApprovedAt}
              />
            )}
          {!readOnly && recommendationStatus === AsyncTitrationStatus.NEW && (
            <div className={actions.container}>
              <NewActions
                mode={mode}
                isDisabled={isSaving}
                onEdit={() => {
                  setMode(SuggestedTitrationMode.Default);
                  onEdit?.({
                    rxnorm: recommendedRxNorm,
                    // if we make it this far, these values will be defined. should be safe to cast
                    doseQuantities: [
                      titrationRecommendation.doseQuantity as number,
                    ],
                    frequencies: [
                      titrationRecommendation.frequency as MedicationChangeFrequency,
                    ],
                  });
                }}
                onAccept={onInitialReviewed}
                onReject={() => toggleMode(SuggestedTitrationMode.NotTitrating)}
              />
            </div>
          )}
          {recommendationStatus ===
            AsyncTitrationStatus.REJECTED_ON_INITIAL_REVIEW && (
            <div className={actions.container}>
              <UndoAction
                variant="rejected"
                onUndo={onUndoInitialReview}
                readOnly={readOnly}
                isProcessing={isSaving}
                rejectedMessage={
                  <FormattedMessage defaultMessage="Titration rejected" />
                }
              />
            </div>
          )}
          {recommendationStatus === AsyncTitrationStatus.INITIALLY_REVIEWED &&
            !isInitialReviewNotePublished && (
              <div className={actions.container}>
                <UndoAction
                  variant="accepted"
                  onUndo={onUndoInitialReview}
                  readOnly={readOnly}
                  isProcessing={isSaving}
                  acceptedHeader={
                    <FormattedMessage defaultMessage="Titration captured" />
                  }
                  acceptedBody={
                    <FormattedMessage defaultMessage="The patient will be notified about the titration suggestion" />
                  }
                />
              </div>
            )}
          {recommendationStatus === AsyncTitrationStatus.INITIALLY_REVIEWED &&
            isInitialReviewNotePublished && (
              <>
                {referenceMed && (
                  <MedicationDetails referenceMed={referenceMed} />
                )}
                <InitiallyReviewedActions
                  mode={mode}
                  isDisabled={isSaving}
                  onAccept={onPatientConsented}
                  onReject={onPatientRejected}
                />
              </>
            )}
          {recommendationStatus === AsyncTitrationStatus.PATIENT_REJECTED && (
            <div className={actions.container}>
              <UndoAction
                variant="rejected"
                onUndo={onUndoPatientDecision}
                readOnly={readOnly}
                isProcessing={isSaving}
                rejectedMessage={
                  <FormattedMessage defaultMessage="Titration rejected by the patient" />
                }
              />
            </div>
          )}
          {recommendationStatus === AsyncTitrationStatus.PATIENT_CONSENTED && (
            <div className={actions.container}>
              <UndoAction
                variant="accepted"
                onUndo={onUndoPatientDecision}
                readOnly={readOnly}
                isProcessing={isSaving}
                acceptedBody={
                  <FormattedMessage defaultMessage="Titration consented to by the patient" />
                }
              />
            </div>
          )}
        </>
      )}
      {mode === SuggestedTitrationMode.NotTitrating && (
        <div className={notTitrating.container}>
          <NotTitratingReasonSelect
            onChange={onRejectedOnInitialReview}
            isDisabled={isSaving}
          />
        </div>
      )}
    </div>
  );
}
