import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import {
  FieldGroup,
  Script,
} from '@/pages/patients/PatientProfile/CNNotesSidebarPanel/shared';
import { scriptLabel } from '@/pages/patients/PatientProfile/CNNotesSidebarPanel/shared/Script/Script.css';
import { useTimeEstimate } from '@/pages/patients/PatientProfile/CNNotesSidebarPanel/shared/useTimeEstimate';
import { Wizard } from '@/shared/common/Wizard';
import { useWizardFormFromConfig } from '@/shared/common/Wizard/state';
import { useShouldMoveUpActionPlan } from '@/shared/common/Wizard/state/sectionUtils';

import { INIT_VISIT_SECTIONS } from '../../metadata';
import { DailyLivingForm } from '../../shared/generalAssessment';
import type { DailyLivingFormFields } from '../../shared/generalAssessment/formConfig';
import { getDailyLivingFormConfig } from '../../shared/generalAssessment/formConfig';
import { initVisitPath } from '../paths';

export function DailyLiving() {
  const intl = useIntl();
  const history = useHistory();
  const form = useWizardFormFromConfig<DailyLivingFormFields>(
    initVisitPath('/general-assessment', '/daily-living'),
    getDailyLivingFormConfig(intl),
  );

  const shouldMoveUpActionPlan = useShouldMoveUpActionPlan();

  return (
    <Wizard.Step
      sections={INIT_VISIT_SECTIONS}
      form={form}
      title={intl.formatMessage({
        defaultMessage: 'Activities of Daily Living',
      })}
      subtitle={useTimeEstimate(
        intl.formatMessage({ defaultMessage: '1-3 min' }),
      )}
      onNext={() =>
        history.push(
          shouldMoveUpActionPlan
            ? initVisitPath('/scheduling', '/index')
            : initVisitPath('/action-plan', '/script'),
        )
      }
    >
      <FieldGroup>
        <DailyLivingForm
          form={form}
          requiresAssistanceLabel={
            <Script>
              <FormattedMessage defaultMessage="Thank you! And lastly, I want to ask you a few specific questions about mobility. Do you require any assistance with walking, eating, using the bathroom, or getting dressed?" />
            </Script>
          }
          classes={{ requiresAssistanceLabel: scriptLabel }}
        />
      </FieldGroup>
    </Wizard.Step>
  );
}
