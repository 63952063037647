import { useObjectRef } from '@react-aria/utils';
import cx from 'classnames';
import type { ReactNode } from 'react';
import { forwardRef } from 'react';
import type { AriaButtonProps } from 'react-aria';
import { FocusRing, useButton } from 'react-aria';
import type { SelectState } from 'react-stately';

import Chevron from '@/shared/assets/svgs/chevron.svg?react';
import { Button } from '@/shared/tempo/atom/Button';
import { input } from '@/shared/tempo/atom/Input/Input.css';
import { focusRingCss } from '@/shared/tempo/util/FocusRing/FocusRing.css';

import { chevron, selectButton } from './SelectButton.css';

type Props = {
  className?: string;
  children: ReactNode;
  hasError?: boolean;
  state: SelectState<unknown>;
} & AriaButtonProps<'button'>;

export const SelectButton = forwardRef<HTMLButtonElement, Props>(
  ({ className, children, hasError, state, ...props }, forwardedRef) => {
    const ref = useObjectRef(forwardedRef);
    const { buttonProps } = useButton(props, ref);

    return (
      <FocusRing
        focusRingClass={cx(
          hasError ? focusRingCss.keyboardWithError : focusRingCss.keyboard,
        )}
      >
        <button
          type="button"
          {...props}
          {...buttonProps}
          ref={ref}
          className={cx(
            {
              // Style the button as though it is an input
              [input.default]: !hasError,
              [input.error]: hasError,
            },
            selectButton,
            className,
          )}
        >
          {children}
          <Button.Icon>
            <Chevron
              className={state.isOpen ? chevron.open : chevron.default}
            />
          </Button.Icon>
        </button>
      </FocusRing>
    );
  },
);
