import { MedPermissions } from '@/pages/patients/PatientMedications/PatientMedicationsList';
import { TypeOfEncounter } from '@/pages/patients/patientDetails/ui/Notes/Notes.types';
import { AsyncTitrationAsyncTitrationStatus as TitrationStatus } from '@/shared/generated/grpcGateway/medication.pb';
import { NoteStatus } from '@/shared/generated/grpcGateway/note.pb';
import { useFlags } from '@/shared/hooks';

import type { TitrationRecommendation } from './useTitrationRecommendation';

type ShouldDisplay = {
  shouldDisplay: boolean;
  readOnly?: boolean;
};

export function useShouldDisplayAsyncTitration(
  asyncTitration: TitrationRecommendation,
  permissions?: MedPermissions,
  noteId?: Maybe<number>,
  typeOfEncounter?: TypeOfEncounter,
): ShouldDisplay {
  const { asyncTitrations: enableAsyncTitrations } = useFlags();
  const { status, initialReviewNoteStatus, consentRequestNoteStatus } =
    asyncTitration;
  const hasTitratePermissions = permissions === MedPermissions.Titrate;
  const isCurrentNoteId = [
    asyncTitration.initialReviewNoteId,
    asyncTitration.consentRequestNoteId,
  ].includes(noteId as number);
  const isPublishedInitiallyReviewedNote =
    initialReviewNoteStatus === NoteStatus.PUBLISHED;
  const isPublishedConsentRequestNote =
    consentRequestNoteStatus === NoteStatus.PUBLISHED;

  if (!status) {
    return { shouldDisplay: false };
  }

  switch (status) {
    case TitrationStatus.NEW:
      return {
        shouldDisplay:
          enableAsyncTitrations &&
          !!noteId &&
          typeOfEncounter === TypeOfEncounter.ASYNC_REVIEW,
        readOnly: !hasTitratePermissions,
      };
    case TitrationStatus.INITIALLY_REVIEWED:
      return {
        shouldDisplay:
          isPublishedInitiallyReviewedNote &&
          hasTitratePermissions &&
          enableAsyncTitrations &&
          typeOfEncounter === TypeOfEncounter.TITRATION_OUTREACH &&
          !!noteId,
        readOnly: !isCurrentNoteId,
      };
    case TitrationStatus.REJECTED_ON_INITIAL_REVIEW:
    case TitrationStatus.REJECTED_ON_FINAL_REVIEW:
      return {
        shouldDisplay:
          enableAsyncTitrations && hasTitratePermissions && isCurrentNoteId,
        readOnly: false,
      };
    case TitrationStatus.APPLIED:
      return { shouldDisplay: false };
    case TitrationStatus.PATIENT_REJECTED:
      return {
        shouldDisplay:
          enableAsyncTitrations &&
          hasTitratePermissions &&
          !!noteId &&
          !isPublishedConsentRequestNote,
        readOnly: !isCurrentNoteId,
      };
    case TitrationStatus.PATIENT_CONSENTED:
      return {
        shouldDisplay:
          enableAsyncTitrations &&
          hasTitratePermissions &&
          !!noteId &&
          !isPublishedConsentRequestNote,
        readOnly: !isCurrentNoteId,
      };
    default:
      return { shouldDisplay: false };
  }
}
