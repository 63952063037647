import debounce from 'lodash/debounce';
import isEqual from 'lodash/isEqual';
import { useCallback, useEffect, useMemo } from 'react';
import type { UseFormReturn } from 'react-hook-form';

import { logger } from '@/logger';
import type { CCMCarePlan } from '@/shared/generated/grpcGateway/ccm_care_plan.pb';
import { usePrevious } from '@/shared/hooks';
import { useSaveCarePlan } from '@/shared/hooks/queries/carePlan.queries';
import { idToGrpcName } from '@/shared/utils/grpc';

import { marshalFormDataToCarePlan } from './dataTransformation';
import type { FormFields } from './formConfig';

export function useOnSave(patientId: string, noteId: Maybe<number>) {
  const {
    mutate: saveCarePlan,
    isLoading: isSaving,
    defaultSuccessCallback,
  } = useSaveCarePlan();

  const onSave = useCallback(
    (
      data: FormFields,
      { onSuccess }: { onSuccess?: (resp: CCMCarePlan) => void } = {},
    ) => {
      const carePlanPayload = marshalFormDataToCarePlan(data);

      if (!noteId) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        return (_: CCMCarePlan) => {
          logger.error('Attempted to save care plan but missing noteId.');
        };
      }

      return saveCarePlan(
        {
          carePlan: carePlanPayload,
          noteId,
          patient: idToGrpcName('patients', patientId),
        },
        {
          onSuccess: async (carePlan) => {
            onSuccess?.(carePlan);
            await defaultSuccessCallback(carePlan);
          },
        },
      );
    },
    [noteId, saveCarePlan, patientId, defaultSuccessCallback],
  );

  return { onSave, isSaving };
}

export function useAutosaveCarePlan(
  form: UseFormReturn<FormFields>,
  saveFn: (data: FormFields) => void,
  { enabled = true }: { enabled: boolean },
) {
  const saveFnDebounced = useMemo(() => debounce(saveFn, 3000), [saveFn]);
  const formData = form.watch();
  const previousFormData = usePrevious(formData);
  const changed = !isEqual(formData, previousFormData);

  useEffect(() => {
    if (changed && enabled) {
      // Need to call getValues() instead of using formData in order to get the
      // most up to date values
      saveFnDebounced(form.getValues());
    }

    // we only care if the form data changes, no need to call
    // this repeatedly for stuff that doesn't matter
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changed]);

  useEffect(() => () => saveFnDebounced.flush(), [saveFnDebounced]);
}
