import type { IntlShape } from 'react-intl';

import { getTitrationRecommendation } from '@/components/AsyncTitration/hooks';
import {
  deliveryMechanismLabel,
  frequencyLabel,
  getRxNormDose,
} from '@/pages/patients/PatientMedications/utils/medInfoUtils';
import type {
  MedicationChange,
  ReferenceMedication,
} from '@/shared/generated/grpcGateway/medication.pb';

type MedicationStrings =
  | {
      medicationName: string;
      dosage: string;
      frequencyText: string;
      clinicianName: string;
      isValid: true;
    }
  | {
      medicationName?: never;
      dosage?: never;
      frequencyText?: never;
      clinicianName?: never;
      isValid: false;
    };

export function getMedicationStrings(
  intl: IntlShape,
  med: MedicationChange,
  referenceMed: ReferenceMedication,
): MedicationStrings {
  const { rxNorm, doseQuantity, frequency, approvingCareProvider } =
    getTitrationRecommendation(med, referenceMed, true, false);

  if (
    !rxNorm ||
    !doseQuantity ||
    !frequency ||
    !approvingCareProvider ||
    !referenceMed.name
  ) {
    return { isValid: false };
  }

  const frequencyText = frequencyLabel(intl, frequency);

  if (!frequencyText) {
    return { isValid: false };
  }

  const medicationName = referenceMed.name.toUpperCase();
  const dosage = `${doseQuantity} ${getRxNormDose(
    rxNorm,
    intl,
  )} ${deliveryMechanismLabel(intl, rxNorm.deliveryMechanism)}`.toLowerCase();
  const clinicianName = `${approvingCareProvider?.givenName} ${approvingCareProvider?.familyName}`;

  return {
    isValid: true,
    medicationName,
    dosage,
    frequencyText: frequencyText.toLowerCase(),
    clinicianName,
  };
}
