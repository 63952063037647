import { TypeOfEncounter } from '@/pages/patients/patientDetails/ui/Notes/Notes.types';

import chfVisit from './files/chfVisit/index.hbs';
import initialCnVisit from './files/initialCnVisit/index.hbs';
import patientFollowUp from './files/patientFollowUp/index.hbs';
import regularCnVisit from './files/regularCnVisit/index.hbs';
import { markdownFieldTemplate } from './template.utils';

export const CN_EXPERIENCE_TEMPLATES_MAP = {
  [TypeOfEncounter.INITIAL_CN_VISIT]: markdownFieldTemplate(initialCnVisit),
  [TypeOfEncounter.CN_VISIT]: markdownFieldTemplate(regularCnVisit),
  [TypeOfEncounter.INITIAL_CN_CHF_VISIT]: markdownFieldTemplate(chfVisit),
  [TypeOfEncounter.CN_CHF_VISIT]: markdownFieldTemplate(chfVisit),
  [TypeOfEncounter.PATIENT_FOLLOW_UP]: markdownFieldTemplate(patientFollowUp),
  [TypeOfEncounter.CYCLE_17_INITIAL_CN_VISIT]:
    markdownFieldTemplate(initialCnVisit),
};
