import cx from 'classnames';
import { endOfDay, formatISO, startOfDay } from 'date-fns';
import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useInterval } from 'usehooks-ts';

import { MenuItem } from '@/deprecated/mui';
import { useListAppointments } from '@/pages/patients/PatientProfile/PatientScheduling/appointments.queries';
import CalendarIcon from '@/shared/assets/svgs/calendar.svg?react';
import { MenuTooltip } from '@/shared/common/Sidebar/MenuTooltip';
import {
  activeMenuItem,
  menuItem,
  sidebarIcon,
  sidebarIconContainer,
} from '@/shared/common/Sidebar/Sidebar.css';
import { SidebarTray } from '@/shared/common/SidebarTray';
import { useCurrentUser } from '@/shared/hooks/useCurrentUser';
import { NotificationBadge } from '@/shared/tempo/atom/NotificationBadge';

import { positionedNotificationBadge } from './ScheduleTray.css';
import { ScheduleTrayBody } from './ScheduleTrayBody';
import { useFlexAwareApptNotifications } from './useFlexAwareApptNotifications';
import type { NotificationInfo } from './utils';

type Props = {
  onOpenChange: (isOpen: boolean) => void;
};

export function ScheduleTray({ onOpenChange }: Props) {
  const label = <FormattedMessage defaultMessage="Appointments" />;
  const [dateUsed, setDateUsed] = useState(new Date());
  const { currentUserId: providerId } = useCurrentUser();
  const [notification, setNotification] = useState<NotificationInfo>({
    startingSoon: false,
    isLate: false,
    minutes: null,
    appointmentId: null,
    patientId: null,
  });
  const intl = useIntl();

  const { data: apptsData, isLoading } = useListAppointments(
    {
      apptStartTimeFrom: formatISO(startOfDay(dateUsed)),
      apptStartTimeTo: formatISO(endOfDay(dateUsed)),
      careProviderId: providerId,
    },
    Boolean(providerId),
  );

  const getFlexAwareNotifDetails = useFlexAwareApptNotifications(providerId);

  useInterval(() => {
    const notifDetails = getFlexAwareNotifDetails();
    setNotification(notifDetails);
  }, 1 * 1000);

  return (
    <SidebarTray
      content={({ state }) => (
        <>
          <SidebarTray.Header onClose={state.close}>
            <FormattedMessage defaultMessage="My Appointments" />
          </SidebarTray.Header>
          <ScheduleTrayBody
            isLoading={isLoading}
            dateUsed={dateUsed}
            onUpdateDateUsed={(date: Date) => {
              setDateUsed(date);
            }}
            appointments={apptsData?.appointments ?? []}
            notification={notification}
          />
        </>
      )}
      onOpenChange={onOpenChange}
    >
      {({ pressProps, state }) => (
        <MenuTooltip label={label}>
          <div className={cx({ [activeMenuItem]: state.isOpen })}>
            <MenuItem {...pressProps} className={menuItem}>
              <div className={sidebarIconContainer}>
                <CalendarIcon className={sidebarIcon} />
                {notification.appointmentId && (
                  <NotificationBadge
                    variant={notification.startingSoon ? 'warn' : 'default'}
                    count={
                      notification.startingSoon
                        ? intl.formatMessage(
                            { defaultMessage: '{duration} mins' },
                            { duration: Math.abs(notification.minutes || 0) },
                          )
                        : intl.formatMessage(
                            { defaultMessage: '+{duration} mins' },
                            { duration: Math.abs(notification.minutes || 0) },
                          )
                    }
                    className={positionedNotificationBadge}
                  />
                )}
              </div>
            </MenuItem>
          </div>
        </MenuTooltip>
      )}
    </SidebarTray>
  );
}
