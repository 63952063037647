import { differenceInYears, parseISO } from 'date-fns';

import { getRegionName } from '@/pages/adminPanel/patient-enrollment-tracker/ui/tabs/EligibleCandidatesTab/utils/getRegionName';
import type { Patient } from '@/shared/types/patient.types';

export const displayGender = (gender: Maybe<string>) => {
  switch (gender) {
    case 'MALE':
      return 'M';
    case 'FEMALE':
      return 'F';
    default:
      return gender;
  }
};

export const displayGenderV2 = (gender: string) => {
  switch (gender) {
    case 'MALE':
      return 'Male';
    case 'FEMALE':
      return 'Female';
    case 'X':
      return 'X';
    default:
      return 'Other';
  }
};

export const displayAge = (dob: string) =>
  differenceInYears(new Date(), parseISO(dob));

export const displayGoogleDateAge = (dob: GoogleDate) =>
  differenceInYears(new Date(), new Date(dob.year, dob.month - 1, dob.day));

export const displayGoogleDateDOB = (dob: GoogleDate) =>
  `${dob.month}/${dob.day}/${dob.year}`;

export const displayPatientLabel = (patient: Patient) => {
  const age = displayAge(patient.dob);
  const gender = displayGender(patient.gender);

  return `${patient.first_name} ${patient.last_name} (${age}, ${gender})`;
};

export const displayHomeState = (patient: Patient) => {
  const primaryAddress = patient?.addresses?.find((address) => address.primary);
  const stateAbbreviation = primaryAddress?.address?.region;

  if (!stateAbbreviation) {
    return 'N/A';
  }

  return getRegionName(stateAbbreviation);
};
