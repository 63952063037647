import cx from 'classnames';
import { type Key, useRef } from 'react';
import type { AriaListBoxProps, AriaSelectOptions } from 'react-aria';
import { HiddenSelect, useSelect } from 'react-aria';
import { FormattedMessage } from 'react-intl';
import {
  useListState,
  useMenuTriggerState,
  useSelectState,
} from 'react-stately';

import { ListBox } from '@/shared/tempo/@labs/atom/ListBox';
import { Popover } from '@/shared/tempo/@labs/molecule/Popover';
import { SelectButton } from '@/shared/tempo/@labs/molecule/Select/SelectButton';
import { useSyncSelectPopoverWidth } from '@/shared/tempo/@labs/molecule/Select/useSyncSelectPopoverWidth';
import { Label } from '@/shared/tempo/atom/Label';
import type { EnforcedA11yLabel } from '@/shared/tempo/shared/types';

import type { SelectPopoverProps } from '../Select/types';
import {
  contents,
  focusRingCss,
  label as labelCss,
  listBoxPopover,
} from './MultiSelect.css';
import { SelectionTagView } from './SelectionTagView';

type Props<T> = {
  className?: string;
  hasError?: boolean;
  placeholder?: string;
  popover?: SelectPopoverProps;
  isLoading?: boolean;
  ignoreKeys?: Iterable<Key>;
} & AriaSelectOptions<T> &
  Omit<AriaListBoxProps<T>, 'selectionMode'> &
  EnforcedA11yLabel;

export function MultiSelect<T extends object>({ ...props }: Props<T>) {
  const {
    placeholder,
    className,
    label,
    hasError,
    popover,
    disabledKeys,
    isLoading,
    ignoreKeys = [],
    'aria-label': ariaLabel,
  } = props;
  const listState = useListState({ ...props, selectionMode: 'multiple' });
  const triggerState = useMenuTriggerState(props);
  const selectState = useSelectState(props);
  const state = { ...selectState, ...listState, ...triggerState };
  const ref = useRef<HTMLButtonElement>(null);
  const popoverRef = useRef<HTMLDivElement>(null);
  const { triggerProps, valueProps, menuProps, labelProps } = useSelect(
    props,
    state,
    ref,
  );

  useSyncSelectPopoverWidth(state, ref, popoverRef, {
    width: popover?.width,
  });

  const selectedKeys = [...state.selectionManager.selectedKeys].filter(
    (k) => ![...ignoreKeys].includes(k),
  );

  return (
    <>
      {label && (
        <Label className={labelCss} label={label} labelProps={labelProps} />
      )}
      <HiddenSelect
        state={state}
        triggerRef={ref}
        {...props}
        aria-multiselectable
      />
      <SelectButton
        {...triggerProps}
        ref={ref}
        hasError={hasError}
        state={state}
        className={cx(
          {
            [focusRingCss.keyboardWithError]: state.isFocused && hasError,
            [focusRingCss.keyboard]: state.isFocused && !hasError,
          },
          className,
        )}
      >
        <span {...valueProps} className={contents}>
          {selectedKeys.length > 0 ? (
            <SelectionTagView
              state={state}
              isLoading={isLoading}
              disabledKeys={disabledKeys}
              ariaLabel={ariaLabel}
              selectButtonRef={ref}
            />
          ) : (
            placeholder || (
              <FormattedMessage defaultMessage="Select an option" />
            )
          )}
        </span>
      </SelectButton>
      {state.isOpen && (
        <Popover
          className={listBoxPopover}
          state={state}
          ref={popoverRef}
          triggerRef={ref}
          placement={popover?.placement || 'bottom'}
        >
          <ListBox.Controlled
            {...menuProps}
            state={state}
            selectionMode="multiple"
          />
        </Popover>
      )}
    </>
  );
}
