import { type ReactNode } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import type { UseInfiniteQueryResult } from 'react-query';
import type { OverlayTriggerState } from 'react-stately';

import { useFlatPages } from '@/reactQuery';
import { InfiniteScrollLoader } from '@/shared/common/InfiniteScrollLoader';
import { LoadingPlaceholder } from '@/shared/common/LoadingPlaceholder';
import type {
  CountTasksByTypeResponse,
  TaskType as TaskTypePb,
} from '@/shared/generated/grpcGateway/task.pb';
import type { PaginatedTasks } from '@/shared/hooks/queries/tasks.queries';
import {
  DEFAULT_ORDER_BY,
  useAssignedTasksInfinite,
  useFollowedTasksInfinite,
  useResolvedTasksInfinite,
  useTaskCountByType,
  useTaskTypesInfinite,
  useTeamTasksInfinite,
} from '@/shared/hooks/queries/tasks.queries';
import { EmptyTasks, PlaceholderImage } from '@/shared/tasking/EmptyTasks';
import { TaskCardCompact } from '@/shared/tasking/TaskCard/TaskCardCompact';
import { TaskCardSkeleton } from '@/shared/tasking/TaskCard/TaskCardSkeleton';
import { TaskCountHeader } from '@/shared/tasking/TaskCountHeader';
import type { ParentView } from '@/shared/tasking/TaskList';
import { TaskList as SharedTaskList } from '@/shared/tasking/TaskList';
import { compactContainer } from '@/shared/tasking/TaskList/TaskList.css';
import type { Task } from '@/shared/tasking/types';
import { TaskState } from '@/shared/tasking/types';

import { Filter } from './Filter';
import { FilterCards } from './FilterCards';
import { TabKey } from './TabKey';
import {
  type TeamTabAssignee,
  useTaskFilterContext,
} from './TaskFilterContext';
import {
  emptyTasksContainer,
  filterDropdownContainer,
  newUiPadding,
  taskListHeader,
} from './TaskHub.css';

type Props = {
  taskHubOverlayState?: OverlayTriggerState;
  parentView: ParentView;
  activeTaskId?: string;
  onOpenTaskDetail?: (task: Task) => void;
};

export function AssignedTasksList(props: Props) {
  const { orderBy, getFilter } = useTaskFilterContext();
  const query = useAssignedTasksInfinite(
    getFilter(TabKey.Open, TaskState.OPENED),
    orderBy,
  );

  return (
    <TaskList
      {...props}
      query={query}
      tabKey={TabKey.Open}
      emptyState={<EmptyTasks placeHolderImage={PlaceholderImage.Mountain} />}
    />
  );
}

export function TeamTasksList(props: Props) {
  const { orderBy, getFilter } = useTaskFilterContext();
  const query = useTeamTasksInfinite(
    getFilter(TabKey.Team, TaskState.OPENED),
    orderBy,
  );

  return (
    <TaskList
      {...props}
      query={query}
      tabKey={TabKey.Team}
      emptyState={<EmptyTasks placeHolderImage={PlaceholderImage.Mountain} />}
    />
  );
}

export function FollowedTasksList(props: Props) {
  const { orderBy, getFilter } = useTaskFilterContext();
  const query = useFollowedTasksInfinite(
    getFilter(TabKey.Following, TaskState.OPENED),
    orderBy,
  );

  return (
    <TaskList
      {...props}
      query={query}
      tabKey={TabKey.Following}
      emptyState={
        <EmptyTasks
          placeHolderImage={PlaceholderImage.Bicycle}
          message={
            <FormattedMessage defaultMessage="You're not following any open tasks" />
          }
        />
      }
    />
  );
}

export function ResolvedTasksList(props: Props) {
  const { orderBy, getFilter } = useTaskFilterContext();
  const query = useResolvedTasksInfinite(
    getFilter(TabKey.Resolved, TaskState.CLOSED),
    orderBy,
  );

  return (
    <TaskList
      {...props}
      query={query}
      tabKey={TabKey.Resolved}
      emptyState={
        <EmptyTasks
          placeHolderImage={PlaceholderImage.Runner}
          message={
            <FormattedMessage defaultMessage="You have no resolved tasks" />
          }
        />
      }
    />
  );
}

type TaskListProps = Props & {
  query: UseInfiniteQueryResult<PaginatedTasks>;
  emptyState: ReactNode;
  tabKey: TabKey;
};

function TaskList({
  taskHubOverlayState,
  query,
  emptyState,
  parentView,
  activeTaskId,
  onOpenTaskDetail,
  tabKey,
}: TaskListProps) {
  const intl = useIntl();
  const {
    taskTypes: filteredTaskTypes,
    onTaskTypesChange,
    orderBy,
    onOrderByChange,
    teamTabAssignee,
    onTeamTabAssigneeChange,
  } = useTaskFilterContext();
  const tasks = useFlatPages<Task, 'data'>(query);
  const taskTypes = useFlatPages<TaskTypePb, 'data'>(useTaskTypesInfinite({}));
  const counts = useTaskCountForTab(tabKey);
  const hasTasks = Object.keys(counts).length > 0;

  // special case for teams tab because the count (represented by hasTasks) is only
  // counting unassigned tasks in the teams tab. we still want users to be able to view
  // assigned tasks in the teams tab even if there are no unassigned tasks.
  const showFilterDropdown = hasTasks || tabKey === TabKey.Team;

  return (
    <>
      {hasTasks && (
        <div className={newUiPadding}>
          <FilterCards
            types={taskTypes}
            selectedType={filteredTaskTypes}
            onChangeSelected={onTaskTypesChange}
            typeCounts={counts}
          />
        </div>
      )}
      <LoadingPlaceholder isLoading={query.isLoading}>
        <div className={taskListHeader}>
          <TaskCountHeader query={query} tabKey={tabKey} />
          {showFilterDropdown && (
            <div className={filterDropdownContainer}>
              {tabKey === TabKey.Team && (
                <Filter
                  buttonVariant="tertiary"
                  emptyMessage={<FormattedMessage defaultMessage="Assignee" />}
                  selectedKey={teamTabAssignee}
                  onSelectionChange={(key) =>
                    onTeamTabAssigneeChange(key as TeamTabAssignee)
                  }
                  items={[
                    {
                      displayName: intl.formatMessage({
                        defaultMessage: 'Unassigned',
                      }),
                      value: 'unassigned',
                    },
                    {
                      displayName: intl.formatMessage({
                        defaultMessage: 'Assigned',
                      }),
                      value: 'assigned',
                    },
                  ]}
                >
                  {({ displayName, value }) => (
                    <Filter.Option key={value}>{displayName}</Filter.Option>
                  )}
                </Filter>
              )}
              <Filter
                buttonVariant="tertiary"
                emptyMessage={<FormattedMessage defaultMessage="Sort by" />}
                selectedKey={orderBy}
                onSelectionChange={(key) => onOrderByChange(key as string)}
                items={[
                  {
                    displayName: intl.formatMessage({
                      defaultMessage: 'Urgency',
                    }),
                    value: DEFAULT_ORDER_BY,
                  },
                  {
                    displayName: intl.formatMessage({
                      defaultMessage: 'Most recent',
                    }),
                    value: 'updateTime desc',
                  },
                  {
                    displayName: intl.formatMessage({
                      defaultMessage: 'Least recent',
                    }),
                    value: 'updateTime asc',
                  },
                ]}
              >
                {({ displayName, value }) => (
                  <Filter.Option key={value}>{displayName}</Filter.Option>
                )}
              </Filter>
            </div>
          )}
        </div>
        {tasks.length ? (
          <SharedTaskList
            tasks={tasks}
            parentView={parentView}
            taskHubOverlayState={taskHubOverlayState}
            classes={{ container: compactContainer }}
            TaskCardComponent={TaskCardCompact}
            isCompact
            activeTaskId={activeTaskId}
            onOpenTaskDetail={onOpenTaskDetail}
          >
            <InfiniteScrollLoader
              query={query}
              loadingPlaceholder={<TaskCardSkeleton />}
              height={48}
            />
          </SharedTaskList>
        ) : (
          <div className={emptyTasksContainer.borderless}>{emptyState}</div>
        )}
      </LoadingPlaceholder>
    </>
  );
}

function useTaskCountForTab(tabKey: TabKey) {
  const { data: taskCounts } = useTaskCountByType();
  const tabCounts = getCountForTab(taskCounts, tabKey) ?? {};

  return Object.keys(tabCounts).reduce(
    (acc, key) => ({
      ...acc,
      [key]: parseInt(tabCounts[key], 10),
    }),
    {} as Record<string, number>,
  );
}

function getCountForTab(
  counts: Maybe<CountTasksByTypeResponse>,
  tabKey: TabKey,
) {
  switch (tabKey) {
    case TabKey.Open:
      return counts?.assigned;
    case TabKey.Following:
      return counts?.following;
    case TabKey.Resolved:
      return counts?.resolved;
    case TabKey.Team:
      return counts?.team;
    default:
      return undefined;
  }
}
