import type { ReactNode } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import mountainImg from '@/shared/assets/mountain.png';
import personOnBikeImg from '@/shared/assets/personOnBike.png';
import runnerImg from '@/shared/assets/runner.png';
import TrayIcon from '@/shared/assets/svgs/tray.svg?react';
import { SimpleEmptyState as EmptyState } from '@/shared/common/EmptyState';

import { emptyStateTitle, trayIcon } from './EmptyTasks.css';
import { PlaceholderImage } from './types';

type Props = {
  children?: ReactNode;
  placeHolderImage?: PlaceholderImage;
  message?: ReactNode;
};

export function EmptyTasks({
  children,
  placeHolderImage = PlaceholderImage.Bicycle,
  message = (
    <FormattedMessage defaultMessage="Relax, you have no tasks at the moment" />
  ),
}: Props) {
  return (
    <EmptyState image={<EmptyStateImage type={placeHolderImage} />}>
      <div className={emptyStateTitle}>{message}</div>
      {children}
    </EmptyState>
  );
}

function EmptyStateImage({ type }: { type: PlaceholderImage }) {
  const intl = useIntl();
  const imageMap = {
    [PlaceholderImage.Bicycle]: (
      <img
        src={personOnBikeImg}
        alt={intl.formatMessage({ defaultMessage: 'person on bicycle' })}
      />
    ),
    [PlaceholderImage.Mountain]: (
      <img
        src={mountainImg}
        alt={intl.formatMessage({ defaultMessage: 'mountain with a stream' })}
      />
    ),

    [PlaceholderImage.Runner]: (
      <img
        src={runnerImg}
        alt={intl.formatMessage({ defaultMessage: 'person running' })}
      />
    ),

    [PlaceholderImage.Tray]: <TrayIcon className={trayIcon} />,
  };

  return imageMap[type];
}
