import cx from 'classnames';
import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import CloseCircleIcon from '@/shared/assets/svgs/closeCircle.svg?react';
import PlusIcon from '@/shared/assets/svgs/plus.svg?react';
import { Form } from '@/shared/common/Form';
import type {
  MedicationChange,
  RxNormDeliveryMechanism,
} from '@/shared/generated/grpcGateway/medication.pb';
import { MedicationChangeStatus } from '@/shared/generated/grpcGateway/medication.pb';
import { Button } from '@/shared/tempo/atom/Button';
import { IconButton } from '@/shared/tempo/atom/IconButton';

import { usePatientMedicationsContext } from '../PatientMedicationsContext';
import { useReferenceMedications } from '../referenceMedications.queries';
import { RxNormInput } from './RxNormInput';
import type { MedFormFields, MedPayload } from './editMedicationFormConfig';
import {
  serializeMedFormFields,
  useMedicationForm,
} from './editMedicationFormConfig';
import {
  doseQuantityFieldLabel,
  doseQuantityPlaceholder,
  frequencyLabels,
} from './formFieldLabels';
import { TitrationFormType } from './formTypeEnum';
import { MaxDoseAchievedField } from './manage/MaximumDoseAchievedField';
import { getMedClasses, getRxNorm, isChfGdmt } from './medClassUtil';
import {
  dateFormField,
  doseRowContainer,
  medFormField,
  medFormInput,
  medFormRow,
  medicationForm,
  removeDoseButton,
  removeDoseIcon,
} from './styles.css';
import { RxNormRestriction } from './types';

export type MedicationFormProps = {
  initValues?: MedicationChange & {
    isAsyncTitration?: boolean;
    asyncTitrationId?: string;
  };
  onClose: () => void;
  referenceId?: string;
  existingReferencedMedIds?: Maybe<string>[];
  rxNormRestriction?: RxNormRestriction;
};
type QueryProps = {
  isProcessing: boolean;
  onSubmit(payload: MedPayload): void;
};

export function MedicationForm({
  initValues,
  isProcessing,
  onSubmit,
  onClose,
  referenceId,
  existingReferencedMedIds,
  rxNormRestriction,
  titrationFormType,
  hasChf = false,
}: MedicationFormProps &
  QueryProps & {
    titrationFormType?: TitrationFormType;
    hasChf?: boolean;
  }) {
  const intl = useIntl();
  const { noteId } = usePatientMedicationsContext();
  const isTitration = Boolean(titrationFormType);

  const [numDoses, setNumDoses] = useState(
    initValues?.doseQuantities?.length || 1,
  );

  function handleRemoveDose(index: number) {
    form.setValue(
      'doseQuantities',
      form.getValues().doseQuantities?.filter((_, i) => i !== index),
    );
    form.setValue(
      'frequencies',
      form.getValues().frequencies?.filter((_, i) => i !== index),
    );

    setNumDoses(numDoses - 1);
  }

  const { data } = useReferenceMedications();
  const initMedClasses = getMedClasses(
    data?.referenceMedications,
    initValues?.rxnorm?.id,
  );
  const form = useMedicationForm(
    isTitration,
    Boolean(
      rxNormRestriction &&
        [
          RxNormRestriction.CURRENT_MED,
          RxNormRestriction.NON_EXISTING_MEDS,
        ].includes(rxNormRestriction) &&
        referenceId,
    ),
    initMedClasses,
    initValues,
    hasChf,
  );

  const isUnverifiedMed =
    initValues?.status === MedicationChangeStatus.NEEDS_REVIEW;
  const statusValue = form.watch('status');
  const showDateFields =
    statusValue === MedicationChangeStatus.ACTIVE ||
    statusValue === MedicationChangeStatus.INACTIVE ||
    isTitration;
  const rxnormId = form.watch('rxnorm')?.norm?.id || form.watch('rxnormId');
  const isChfGdmtMed =
    hasChf && isChfGdmt(data?.referenceMedications, rxnormId);
  const rxnorm = getRxNorm(data?.referenceMedications, rxnormId);

  function handleSubmit(values: MedFormFields) {
    const payload = serializeMedFormFields(values, noteId);
    onSubmit({
      ...payload,
      asyncTitration: {
        isAsyncTitration: initValues?.isAsyncTitration ?? false,
        asyncTitrationId: initValues?.asyncTitrationId ?? '',
        rxcui: rxnorm?.rxcui ?? '',
      },
    });
  }

  return (
    <Form form={form} onSubmit={handleSubmit}>
      {({ canSubmit }) => (
        <div className={medicationForm}>
          <RxNormInput
            referenceMeds={data?.referenceMedications}
            referenceId={referenceId}
            existingReferencedMedIds={existingReferencedMedIds}
            rxNormRestriction={rxNormRestriction}
            proposedRxNorm={
              initValues?.isAsyncTitration ? initValues?.rxnorm : undefined
            }
          />
          <DoseRows
            deliveryMechanism={rxnorm?.deliveryMechanism}
            rowCount={numDoses}
            onRemoveDose={handleRemoveDose}
            isNewTitration={titrationFormType === TitrationFormType.Titrate}
          />
          <div>
            <Button
              variant="tertiary"
              onPress={() => setNumDoses(numDoses + 1)}
            >
              <Button.Icon>
                <PlusIcon />
              </Button.Icon>
              <FormattedMessage defaultMessage="Split dose" />
            </Button>
          </div>
          {!isTitration && <StatusField isUnverified={isUnverifiedMed} />}
          {showDateFields && (
            <div className={medFormRow}>
              {isTitration && (
                <Form.DatePicker
                  className={cx(medFormField, dateFormField)}
                  name="startDate"
                  label={intl.formatMessage({ defaultMessage: 'Start date' })}
                  inputClass={medFormInput}
                />
              )}
              <Form.DatePicker
                className={cx(medFormField, dateFormField)}
                name="endDate"
                label={intl.formatMessage({ defaultMessage: 'End date' })}
                inputClass={medFormInput}
                required={statusValue === MedicationChangeStatus.INACTIVE}
              />
            </div>
          )}
          {isChfGdmtMed && <MaxDoseAchievedField />}
          <PatientNotesField />
          <Form.Actions direction="row-reverse">
            <Button
              variant="primary"
              size="small"
              onPress={() => form.handleSubmit(handleSubmit)()}
              isProcessing={isProcessing}
              isDisabled={!canSubmit}
            >
              {initValues ? (
                <FormattedMessage defaultMessage="Update" />
              ) : (
                <FormattedMessage defaultMessage="Submit" />
              )}
            </Button>
            <Button variant="secondary" size="small" onPress={onClose}>
              <FormattedMessage defaultMessage="Cancel" />
            </Button>
          </Form.Actions>
        </div>
      )}
    </Form>
  );
}

function StatusField({ isUnverified }: { isUnverified: boolean }) {
  const intl = useIntl();
  return (
    <Form.Row className={medFormRow}>
      <Form.RadioGroup
        className={medFormField}
        name="status"
        label={intl.formatMessage({
          defaultMessage: 'Is this an active medication?',
        })}
        orientation="horizontal"
        size={12}
      >
        <Form.Radio value={MedicationChangeStatus.ACTIVE}>
          <FormattedMessage defaultMessage="Yes" />
        </Form.Radio>
        <Form.Radio value={MedicationChangeStatus.INACTIVE}>
          <FormattedMessage defaultMessage="No" />
        </Form.Radio>
        {isUnverified && (
          <Form.Radio value={MedicationChangeStatus.NEEDS_REVIEW}>
            <FormattedMessage defaultMessage="Unverified (chart prep)" />
          </Form.Radio>
        )}
      </Form.RadioGroup>
    </Form.Row>
  );
}

export function PatientNotesField() {
  const intl = useIntl();
  return (
    <Form.TextArea
      name="reason"
      size={12}
      rows={3}
      maxLength={255}
      label={intl.formatMessage({ defaultMessage: 'Patient notes' })}
      placeholder={intl.formatMessage({
        defaultMessage: 'Enter optional notes',
      })}
      classes={{
        root: medFormField,
        input: medFormInput,
      }}
    />
  );
}

function DoseRows({
  rowCount,
  deliveryMechanism,
  onRemoveDose,
  isNewTitration,
}: {
  rowCount: number;
  deliveryMechanism: Maybe<RxNormDeliveryMechanism>;
  onRemoveDose: (index: number) => void;
  isNewTitration: boolean;
}) {
  const intl = useIntl();

  const rows = [];
  for (let i = 0; i < rowCount; i++) {
    rows.push(
      <div className={doseRowContainer}>
        <Form.Row className={medFormRow} columnSpacing={3}>
          <Form.TextField
            className={medFormField}
            name={`doseQuantities[${i}]`}
            label={doseQuantityFieldLabel(intl, deliveryMechanism)}
            size={4}
            inputClass={medFormInput}
            placeholder={intl.formatMessage({ defaultMessage: '#' })}
            endAdornmentText={doseQuantityPlaceholder(intl, deliveryMechanism)}
          />
          <Form.DeprecatedSelect
            className={medFormField}
            name={`frequencies[${i}]`}
            label={
              isNewTitration
                ? intl.formatMessage({ defaultMessage: 'New frequency' })
                : intl.formatMessage({ defaultMessage: 'Frequency' })
            }
            size={8}
            inputClass={medFormInput}
          >
            <option value="">
              {intl.formatMessage({
                defaultMessage: '-- Select frequency --',
              })}
            </option>
            {Object.entries(frequencyLabels(intl)).map(([value, label]) => (
              <option key={value} value={value}>
                {label}
              </option>
            ))}
          </Form.DeprecatedSelect>
        </Form.Row>
        {rowCount > 1 && (
          // TODO: fix alignment when error message
          // https://cadencerpm.atlassian.net/browse/PLAT-6275
          <IconButton
            className={removeDoseButton}
            variant="tertiary"
            size="small"
            onPress={() => onRemoveDose(i)}
          >
            <CloseCircleIcon className={removeDoseIcon} />
          </IconButton>
        )}
      </div>,
    );
  }
  return <>{rows}</>;
}
