import { lastStructuredChange } from '@/pages/patients/PatientMedications/utils/medChangeUtils';
import type {
  Medication,
  MedicationChange,
  ReferenceMedication,
} from '@/shared/generated/grpcGateway/medication.pb';
import { AsyncTitrationAsyncTitrationStatus as AsyncTitrationStatus } from '@/shared/generated/grpcGateway/medication.pb';

import { TypeOfEncounter } from '../../../Notes.types';
import type { AsyncTitrationEncounterType } from './types';

// a med change is deemed relevant if it:
//   a) is the most recent med change,
//   b) has an async titration in one of the statuses defined below, and
//   c) the async titration change took place in the current note
// changes are sorted such that all approved (i.e. initially reviewed
// and patient consented) titration recommendations come first, followed
// by rejected (i.e. rejected on initial review and patient rejected) titration
// recommendations. if we have multiple titration suggestions in the same
// state, they will be sorted alphabetically by reference medication name
const RELEVANT_STATUSES = {
  [TypeOfEncounter.ASYNC_REVIEW]: [
    AsyncTitrationStatus.INITIALLY_REVIEWED,
    AsyncTitrationStatus.REJECTED_ON_INITIAL_REVIEW,
  ],
  [TypeOfEncounter.TITRATION_OUTREACH]: [
    AsyncTitrationStatus.PATIENT_CONSENTED,
    AsyncTitrationStatus.PATIENT_REJECTED,
  ],
};

export function getSortedRelevantMedChanges(
  encounterType: AsyncTitrationEncounterType,
  noteId: number,
  patientMeds?: Medication[],
  referenceMeds?: ReferenceMedication[],
): [ReferenceMedication, MedicationChange][] {
  if (!patientMeds) {
    return [];
  }

  const relevantStatuses = RELEVANT_STATUSES[encounterType];

  return patientMeds
    .map((med) => {
      const referenceMed = referenceMeds?.find(
        (refMed) => refMed.id?.toString() === med.referenceMedicationId,
      );

      return [referenceMed, lastStructuredChange(med)] as const;
    })
    .filter((medInfo): medInfo is [ReferenceMedication, MedicationChange] => {
      const [referenceMed, med] = medInfo;

      if (!med || !referenceMed || !med.asyncTitration) {
        return false;
      }

      if (
        encounterType === TypeOfEncounter.ASYNC_REVIEW &&
        med.asyncTitration.initialReviewNoteId !== noteId
      ) {
        return false;
      }

      if (
        encounterType === TypeOfEncounter.TITRATION_OUTREACH &&
        med.asyncTitration.consentRequestNoteId !== noteId
      ) {
        return false;
      }

      return relevantStatuses.includes(
        med.asyncTitration.status as AsyncTitrationStatus,
      );
    })
    .sort(([refMedA, changeA], [refMedB, changeB]) => {
      const statusA = changeA.asyncTitration?.status;
      const statusB = changeB.asyncTitration?.status;
      const nameA = refMedA.name;
      const nameB = refMedB.name;

      // if statuses are identical, sort by alpha med name. if we don't
      // have those for whatever reason, just leave them in their original
      // positions in the array
      if (statusA === statusB) {
        return nameA && nameB ? nameA.localeCompare(nameB) : 0;
      }

      return statusA === relevantStatuses[0] ? -1 : 1;
    });
}
