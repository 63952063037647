import type { IntlShape } from 'react-intl';

import type { TitrationRecommendation } from '@/components/AsyncTitration/hooks';
import { logger } from '@/logger';
import type {
  MedicationChange,
  RxNorm,
} from '@/shared/generated/grpcGateway/medication.pb';
import {
  MedicationChangeFrequency,
  RxNormDeliveryMechanism,
  RxNormMedicationUnit,
  RxNormReleaseType,
} from '@/shared/generated/grpcGateway/medication.pb';

import {
  frequencyLabels,
  rxNormDeliveryMechanismLabels,
  rxNormMedicationUnitLabels,
  rxNormReleaseTypeLabels,
} from '../forms/formFieldLabels';
import { DoseType, getDoseType } from './medChangeUtils';

const medicationChangeFrequencyMultipliers = {
  [MedicationChangeFrequency.FREQUENCY_UNSPECIFIED]: 0,
  [MedicationChangeFrequency.DAILY]: 1,
  [MedicationChangeFrequency.DAILY_AM]: 1,
  [MedicationChangeFrequency.DAILY_PM]: 1,
  [MedicationChangeFrequency.TWICE_A_DAY]: 2,
  [MedicationChangeFrequency.THREE_TIMES_A_DAY]: 3,
  [MedicationChangeFrequency.FOUR_TIMES_A_DAY]: 4,
  [MedicationChangeFrequency.EVERY_OTHER_DAY]: 0.5,
  [MedicationChangeFrequency.ONCE_A_WEEK]: 1 / 7,
  [MedicationChangeFrequency.TWICE_A_WEEK]: 2 / 7,
  [MedicationChangeFrequency.MONTHLY]: 1 / 30,
  [MedicationChangeFrequency.AS_NEEDED]: 0,
  [MedicationChangeFrequency.HOURLY]: 24,
  [MedicationChangeFrequency.EVERY_BEDTIME]: 1,
  [MedicationChangeFrequency.WITH_MEALS]: 3,
};

export function getRxNormStr(rxnorm: RxNorm, intl: IntlShape) {
  const doseType = getDoseType(rxnorm);

  const brand = rxnorm?.brand ? `[${rxnorm?.brand}] `.toUpperCase() : '';
  let rxNormComponents =
    rxnorm?.components
      ?.map((component) => {
        const ingredient = component.ingredient?.toUpperCase();
        const { strength } = component;
        const unit = unitLabel(intl, rxnorm?.unit);
        return `${ingredient} ${strength} ${unit}`;
      })
      .join(' - ') || '';

  if (
    doseType === DoseType.PILL &&
    rxnorm?.releaseType &&
    ![
      RxNormReleaseType.RELEASE_TYPE_UNSPECIFIED,
      RxNormReleaseType.IMMEDIATE,
    ].includes(rxnorm?.releaseType)
  ) {
    rxNormComponents = [
      rxNormComponents,
      ` ${releaseTypeLabel(intl, rxnorm?.releaseType) || ''}`,
    ].join('');
  }
  return `${brand}${rxNormComponents}`;
}

export function getMedChangeDailyDosage(
  medChange: MedicationChange,
  intl: IntlShape,
) {
  return getDailyDosage(
    medChange.rxnorm,
    medChange?.doseQuantities,
    medChange?.frequencies,
    intl,
  );
}

export function getTitrationRecommendationDailyDosage(
  titrationRecommendation: TitrationRecommendation,
  intl: IntlShape,
) {
  return getDailyDosage(
    titrationRecommendation.rxNorm,
    titrationRecommendation.doseQuantity
      ? [titrationRecommendation.doseQuantity]
      : [],
    titrationRecommendation.frequency
      ? [titrationRecommendation.frequency]
      : [],
    intl,
  );
}

function getDailyDosage(
  rxNorm: RxNorm | undefined,
  splitDoseQuantities: number[] | undefined,
  splitDoseFrequencies: MedicationChangeFrequency[] | undefined,
  intl: IntlShape,
) {
  const components = rxNorm?.components;
  const unitText = unitLabel(intl, rxNorm?.unit);
  if (
    !components ||
    !unitText ||
    !splitDoseQuantities ||
    !splitDoseFrequencies
  ) {
    logger.error(
      'medChange/rxNorm is missing one or more of: components, unit, doseQuantities, frequencies',
    );
    return '';
  }

  // Strength per component when combo med, just one item in the array otherwise
  const componentDoseStrengths = components?.map(
    (component) => component.strength,
  );

  const totalDailyDosages = componentDoseStrengths.map((strength = 0) => {
    const totalQuantity = splitDoseQuantities.reduce(
      (acc, quantity, i) =>
        acc +
        quantity *
          medicationChangeFrequencyMultipliers[splitDoseFrequencies[i]],
      0,
    );
    return `${strength * totalQuantity}${unitText}`;
  });

  return totalDailyDosages.join(', ');
}

export function getRxNormDose(rxNorm: RxNorm, intl: IntlShape) {
  const components = rxNorm.components?.map((component) => {
    const { strength } = component;
    const unit = unitLabel(intl, rxNorm.unit);

    return `${strength}${unit}`;
  });

  return components?.join(', ');
}

function unitLabel(intl: IntlShape, unit?: RxNormMedicationUnit) {
  if (!unit || unit === RxNormMedicationUnit.MEDICATION_UNIT_UNSPECIFIED) {
    logger.error('Unit not found');
    return null;
  }
  const labels = rxNormMedicationUnitLabels(intl);
  return labels[unit];
}

function releaseTypeLabel(intl: IntlShape, releaseType?: RxNormReleaseType) {
  if (
    !releaseType ||
    releaseType === RxNormReleaseType.RELEASE_TYPE_UNSPECIFIED
  ) {
    return null;
  }
  const labels = rxNormReleaseTypeLabels(intl);
  return labels[releaseType];
}

export function getDoseStr(
  intl: IntlShape,
  doseQuantities: Maybe<number[]>,
  frequencies: Maybe<MedicationChangeFrequency[]>,
  rxnorm: Maybe<RxNorm>,
) {
  const doseType = getDoseType(rxnorm);

  const doseComponents = doseQuantities?.map((quantity, i) => {
    const frequency = frequencyLabel(intl, frequencies?.[i]);
    const deliveryType = deliveryMechanismLabel(
      intl,
      rxnorm?.deliveryMechanism,
    );
    if (doseType === DoseType.PILL) {
      return `take ${quantity} ${deliveryType || ''} ${frequency}`;
    }
    return `${quantity} unit(s) ${deliveryType || ''} ${frequency}`;
  });
  return doseComponents?.join('; ');
}

export function frequencyLabel(
  intl: IntlShape,
  frequency?: MedicationChangeFrequency,
) {
  if (
    !frequency ||
    frequency === MedicationChangeFrequency.FREQUENCY_UNSPECIFIED
  ) {
    logger.error(
      'Frequency corresponding with dose quantity expected but not found',
    );
    return null;
  }
  const labels = frequencyLabels(intl);
  return labels[frequency];
}

export function deliveryMechanismLabel(
  intl: IntlShape,
  mechanism?: RxNormDeliveryMechanism,
) {
  if (
    !mechanism ||
    mechanism === RxNormDeliveryMechanism.DELIVERY_MECHANISM_UNSPECIFIED
  ) {
    return null;
  }
  const labels = rxNormDeliveryMechanismLabels(intl);
  return labels[mechanism];
}
