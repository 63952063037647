import { usePatientMedications } from '@/pages/patients/PatientMedications/patientMedications.queries';
import type { PatientMedications } from '@/shared/generated/grpcGateway/medication.pb';

import type { TypeOfEncounter } from '../../Notes.types';
import { getMedicationsMarkdownFromNode } from '../getMarkdownFromNode';
import { MedicationsNotePreview } from './MedicationsNotePreview';

export function useGetMedReviewNoteBody(
  patientId: string,
  noteId: Maybe<number>,
) {
  const { data } = usePatientMedications(patientId);

  return (encounterType?: TypeOfEncounter) => {
    if (!data || !noteId) {
      return null;
    }

    return getMedicationNotePreview(noteId, data, encounterType);
  };
}

export function getMedicationNotePreview(
  noteId: number,
  data: PatientMedications,
  encounterType?: TypeOfEncounter,
  hideOtherMeds?: boolean,
) {
  return getMedicationsMarkdownFromNode(
    <MedicationsNotePreview
      data={data}
      noteId={noteId}
      encounterType={encounterType}
      hideOtherMeds={hideOtherMeds}
    />,
  );
}
