/* eslint-disable */
// @ts-nocheck
/*
* This file is a generated Typescript file for GRPC Gateway, DO NOT MODIFY
*/

import * as CadenceModelsV1Models from "./cadence/models/models.pb"
import * as GoogleProtobufEmpty from "./google/protobuf/empty.pb"
import * as GoogleProtobufField_mask from "./google/protobuf/field_mask.pb"
import * as GoogleProtobufStruct from "./google/protobuf/struct.pb"
import * as GoogleProtobufTimestamp from "./google/protobuf/timestamp.pb"
import * as GoogleProtobufWrappers from "./google/protobuf/wrappers.pb"
import * as GoogleTypeDate from "./google/type/date.pb"
import * as fm from "./fetch.pb"

type Absent<T, K extends keyof T> = { [k in Exclude<keyof T, K>]?: undefined };
type OneOf<T> =
  | { [k in keyof T]?: undefined }
  | (
    keyof T extends infer K ?
      (K extends string & keyof T ? { [k in K]: T[K] } & Absent<T, K>
        : never)
    : never);

export enum EMRResourceType {
  EMR_RESOURCE_TYPE_UNSPECIFIED = "EMR_RESOURCE_TYPE_UNSPECIFIED",
  PROVIDER = "PROVIDER",
  DEPARTMENT = "DEPARTMENT",
  APPOINTMENT = "APPOINTMENT",
  PATIENT = "PATIENT",
}

export enum ProgramType {
  PROGRAM_TYPE_UNSPECIFIED = "PROGRAM_TYPE_UNSPECIFIED",
  RPM = "RPM",
  CCM = "CCM",
}

export enum PrimaryLanguage {
  PRIMARY_LANGUAGE_UNSPECIFIED = "PRIMARY_LANGUAGE_UNSPECIFIED",
  ENGLISH = "ENGLISH",
  SPANISH = "SPANISH",
  ARABIC = "ARABIC",
  CHINESE = "CHINESE",
  FILIPINO = "FILIPINO",
  FRENCH = "FRENCH",
  GERMAN = "GERMAN",
  HAITIAN_CREOLE = "HAITIAN_CREOLE",
  HINDI = "HINDI",
  ITALIAN = "ITALIAN",
  JAPANESE = "JAPANESE",
  KOREAN = "KOREAN",
  PERSIAN = "PERSIAN",
  POLISH = "POLISH",
  PORTUGUESE = "PORTUGUESE",
  ROMANIAN = "ROMANIAN",
  RUSSIAN = "RUSSIAN",
  UKRAINIAN = "UKRAINIAN",
  URDU = "URDU",
  VIETNAMESE = "VIETNAMESE",
  AFAR = "AFAR",
  ABKHAZIAN = "ABKHAZIAN",
  ACHINESE = "ACHINESE",
  ACOLI = "ACOLI",
  ADANGME = "ADANGME",
  ADYGHE = "ADYGHE",
  AFRO_ASIATIC_LANGUAGES = "AFRO_ASIATIC_LANGUAGES",
  AFRIHILI = "AFRIHILI",
  AINU = "AINU",
  AKAN = "AKAN",
  AKKADIAN = "AKKADIAN",
  ALEUT = "ALEUT",
  ALGONQUIAN_LANGUAGES = "ALGONQUIAN_LANGUAGES",
  SOUTHERN_ALTAI = "SOUTHERN_ALTAI",
  AMHARIC = "AMHARIC",
  OLD_ENGLISH = "OLD_ENGLISH",
  ANGIKA = "ANGIKA",
  APACHE_LANGUAGES = "APACHE_LANGUAGES",
  ARAMAIC = "ARAMAIC",
  ARAGONESE = "ARAGONESE",
  MAPUCHE = "MAPUCHE",
  ARAPAHO = "ARAPAHO",
  ARTIFICIAL_LANGUAGES = "ARTIFICIAL_LANGUAGES",
  ARAWAK = "ARAWAK",
  ASSAMESE = "ASSAMESE",
  ASTURIAN = "ASTURIAN",
  ATHAPASCAN_LANGUAGES = "ATHAPASCAN_LANGUAGES",
  AUSTRALIAN_LANGUAGES = "AUSTRALIAN_LANGUAGES",
  AVARIC = "AVARIC",
  AVESTAN = "AVESTAN",
  AWADHI = "AWADHI",
  AYMARA = "AYMARA",
  BANDA_LANGUAGES = "BANDA_LANGUAGES",
  BAMILEKE_LANGUAGES = "BAMILEKE_LANGUAGES",
  BASHKIR = "BASHKIR",
  BALUCHI = "BALUCHI",
  BAMBARA = "BAMBARA",
  BALINESE = "BALINESE",
  BASQUE = "BASQUE",
  BASAA = "BASAA",
  BALTIC_LANGUAGES = "BALTIC_LANGUAGES",
  BEJA = "BEJA",
  BELARUSIAN = "BELARUSIAN",
  BEMBA = "BEMBA",
  BANGLA = "BANGLA",
  BERBER_LANGUAGES = "BERBER_LANGUAGES",
  BHOJPURI = "BHOJPURI",
  BIHARI_LANGUAGES = "BIHARI_LANGUAGES",
  BIKOL = "BIKOL",
  BINI = "BINI",
  BISLAMA = "BISLAMA",
  SIKSIKA = "SIKSIKA",
  BANTU_LANGUAGES = "BANTU_LANGUAGES",
  BOSNIAN = "BOSNIAN",
  BRAJ = "BRAJ",
  BRETON = "BRETON",
  BATAK_LANGUAGES = "BATAK_LANGUAGES",
  BURIAT = "BURIAT",
  BUGINESE = "BUGINESE",
  BULGARIAN = "BULGARIAN",
  BLIN = "BLIN",
  CADDO = "CADDO",
  CENTRAL_AMERICAN_INDIAN_LANGUAGES = "CENTRAL_AMERICAN_INDIAN_LANGUAGES",
  CARIB = "CARIB",
  CATALAN = "CATALAN",
  CAUCASIAN_LANGUAGES = "CAUCASIAN_LANGUAGES",
  CEBUANO = "CEBUANO",
  CELTIC_LANGUAGES = "CELTIC_LANGUAGES",
  CZECH = "CZECH",
  CHAMORRO = "CHAMORRO",
  CHIBCHA = "CHIBCHA",
  CHECHEN = "CHECHEN",
  CHAGATAI = "CHAGATAI",
  CHUUKESE = "CHUUKESE",
  MARI = "MARI",
  CHINOOK_JARGON = "CHINOOK_JARGON",
  CHOCTAW = "CHOCTAW",
  CHIPEWYAN = "CHIPEWYAN",
  CHEROKEE = "CHEROKEE",
  CHURCH_SLAVIC = "CHURCH_SLAVIC",
  CHUVASH = "CHUVASH",
  CHEYENNE = "CHEYENNE",
  CHAMIC_LANGUAGES = "CHAMIC_LANGUAGES",
  SERBIAN_MONTENEGRO = "SERBIAN_MONTENEGRO",
  COPTIC = "COPTIC",
  CORNISH = "CORNISH",
  CORSICAN = "CORSICAN",
  ENGLISH_BASED_CREOLES_AND_PIDGINS = "ENGLISH_BASED_CREOLES_AND_PIDGINS",
  FRENCH_BASED_CREOLES_AND_PIDGINS = "FRENCH_BASED_CREOLES_AND_PIDGINS",
  PORTUGUESE_BASED_CREOLES_AND_PIDGINS = "PORTUGUESE_BASED_CREOLES_AND_PIDGINS",
  CREE = "CREE",
  CRIMEAN_TURKISH = "CRIMEAN_TURKISH",
  CREOLES_AND_PIDGINS = "CREOLES_AND_PIDGINS",
  KASHUBIAN = "KASHUBIAN",
  CUSHITIC_LANGUAGES = "CUSHITIC_LANGUAGES",
  DAKOTA = "DAKOTA",
  DANISH = "DANISH",
  DARGWA = "DARGWA",
  LAND_DAYAK_LANGUAGES = "LAND_DAYAK_LANGUAGES",
  DELAWARE = "DELAWARE",
  SLAVE = "SLAVE",
  DOGRIB = "DOGRIB",
  DINKA = "DINKA",
  DIVEHI = "DIVEHI",
  DOGRI = "DOGRI",
  DRAVIDIAN_LANGUAGES = "DRAVIDIAN_LANGUAGES",
  LOWER_SORBIAN = "LOWER_SORBIAN",
  DUALA = "DUALA",
  MIDDLE_DUTCH = "MIDDLE_DUTCH",
  DUTCH = "DUTCH",
  DYULA = "DYULA",
  DZONGKHA = "DZONGKHA",
  EFIK = "EFIK",
  ANCIENT_EGYPTIAN = "ANCIENT_EGYPTIAN",
  EKAJUK = "EKAJUK",
  GREEK = "GREEK",
  ELAMITE = "ELAMITE",
  MIDDLE_ENGLISH = "MIDDLE_ENGLISH",
  ESPERANTO = "ESPERANTO",
  ESTONIAN = "ESTONIAN",
  EWE = "EWE",
  EWONDO = "EWONDO",
  FANG = "FANG",
  FAROESE = "FAROESE",
  FANTI = "FANTI",
  FIJIAN = "FIJIAN",
  FINNISH = "FINNISH",
  FINNO_UGRIAN_LANGUAGES = "FINNO_UGRIAN_LANGUAGES",
  FON = "FON",
  MIDDLE_FRENCH = "MIDDLE_FRENCH",
  OLD_FRENCH = "OLD_FRENCH",
  NORTHERN_FRISIAN = "NORTHERN_FRISIAN",
  EASTERN_FRISIAN = "EASTERN_FRISIAN",
  WESTERN_FRISIAN = "WESTERN_FRISIAN",
  FULAH = "FULAH",
  FRIULIAN = "FRIULIAN",
  GA = "GA",
  GAYO = "GAYO",
  GBAYA = "GBAYA",
  GERMANIC_LANGUAGES = "GERMANIC_LANGUAGES",
  GEORGIAN = "GEORGIAN",
  GEEZ = "GEEZ",
  GILBERTESE = "GILBERTESE",
  SCOTTISH_GAELIC = "SCOTTISH_GAELIC",
  IRISH = "IRISH",
  GALICIAN = "GALICIAN",
  MANX = "MANX",
  MIDDLE_HIGH_GERMAN = "MIDDLE_HIGH_GERMAN",
  OLD_HIGH_GERMAN = "OLD_HIGH_GERMAN",
  GONDI = "GONDI",
  GORONTALO = "GORONTALO",
  GOTHIC = "GOTHIC",
  GREBO = "GREBO",
  ANCIENT_GREEK = "ANCIENT_GREEK",
  GUARANI = "GUARANI",
  SWISS_GERMAN = "SWISS_GERMAN",
  GUJARATI = "GUJARATI",
  GWICHIN = "GWICHIN",
  HAIDA = "HAIDA",
  HAUSA = "HAUSA",
  HAWAIIAN = "HAWAIIAN",
  HEBREW = "HEBREW",
  HERERO = "HERERO",
  HILIGAYNON = "HILIGAYNON",
  HIMACHALI_LANGUAGES = "HIMACHALI_LANGUAGES",
  HITTITE = "HITTITE",
  HMONG = "HMONG",
  HIRI_MOTU = "HIRI_MOTU",
  CROATIAN = "CROATIAN",
  UPPER_SORBIAN = "UPPER_SORBIAN",
  HUNGARIAN = "HUNGARIAN",
  HUPA = "HUPA",
  IBAN = "IBAN",
  IGBO = "IGBO",
  ICELANDIC = "ICELANDIC",
  IDO = "IDO",
  SICHUAN_YI = "SICHUAN_YI",
  IJO_LANGUAGES = "IJO_LANGUAGES",
  INUKTITUT = "INUKTITUT",
  INTERLINGUE = "INTERLINGUE",
  ILOKO = "ILOKO",
  INTERLINGUA = "INTERLINGUA",
  INDIC_LANGUAGES = "INDIC_LANGUAGES",
  INDONESIAN = "INDONESIAN",
  INDO_EUROPEAN_LANGUAGES = "INDO_EUROPEAN_LANGUAGES",
  INGUSH = "INGUSH",
  INUPIAQ = "INUPIAQ",
  IRANIAN_LANGUAGES = "IRANIAN_LANGUAGES",
  IROQUOIAN_LANGUAGES = "IROQUOIAN_LANGUAGES",
  JAVANESE = "JAVANESE",
  LOJBAN = "LOJBAN",
  JUDEO_PERSIAN = "JUDEO_PERSIAN",
  JUDEO_ARABIC = "JUDEO_ARABIC",
  KARA_KALPAK = "KARA_KALPAK",
  KABYLE = "KABYLE",
  KACHIN = "KACHIN",
  KALAALLISUT = "KALAALLISUT",
  KAMBA = "KAMBA",
  KANNADA = "KANNADA",
  KAREN_LANGUAGES = "KAREN_LANGUAGES",
  KASHMIRI = "KASHMIRI",
  KANURI = "KANURI",
  KAWI = "KAWI",
  KAZAKH = "KAZAKH",
  KABARDIAN = "KABARDIAN",
  KHASI = "KHASI",
  KHOISAN_LANGUAGES = "KHOISAN_LANGUAGES",
  KHMER = "KHMER",
  KHOTANESE = "KHOTANESE",
  KIKUYU = "KIKUYU",
  KINYARWANDA = "KINYARWANDA",
  KYRGYZ = "KYRGYZ",
  KIMBUNDU = "KIMBUNDU",
  KONKANI = "KONKANI",
  KOMI = "KOMI",
  KONGO = "KONGO",
  KOSRAEAN = "KOSRAEAN",
  KPELLE = "KPELLE",
  KARACHAY_BALKAR = "KARACHAY_BALKAR",
  KARELIAN = "KARELIAN",
  KRU_LANGUAGES = "KRU_LANGUAGES",
  KURUKH = "KURUKH",
  KUANYAMA = "KUANYAMA",
  KUMYK = "KUMYK",
  KURDISH = "KURDISH",
  KUTENAI = "KUTENAI",
  LADINO = "LADINO",
  LAHNDA = "LAHNDA",
  LAMBA = "LAMBA",
  LAO = "LAO",
  LATIN = "LATIN",
  LATVIAN = "LATVIAN",
  LEZGHIAN = "LEZGHIAN",
  LIMBURGISH = "LIMBURGISH",
  LINGALA = "LINGALA",
  LITHUANIAN = "LITHUANIAN",
  MONGO = "MONGO",
  LOZI = "LOZI",
  LUXEMBOURGISH = "LUXEMBOURGISH",
  LUBA_LULUA = "LUBA_LULUA",
  LUBA_KATANGA = "LUBA_KATANGA",
  GANDA = "GANDA",
  LUISENO = "LUISENO",
  LUNDA = "LUNDA",
  LUO_KENYA_AND_TANZANIA = "LUO_KENYA_AND_TANZANIA",
  MIZO = "MIZO",
  MACEDONIAN = "MACEDONIAN",
  MADURESE = "MADURESE",
  MAGAHI = "MAGAHI",
  MARSHALLESE = "MARSHALLESE",
  MAITHILI = "MAITHILI",
  MAKASAR = "MAKASAR",
  MALAYALAM = "MALAYALAM",
  MANDINGO = "MANDINGO",
  MAORI = "MAORI",
  AUSTRONESIAN_LANGUAGES = "AUSTRONESIAN_LANGUAGES",
  MARATHI = "MARATHI",
  MASAI = "MASAI",
  MOKSHA = "MOKSHA",
  MANDAR = "MANDAR",
  MENDE = "MENDE",
  MIDDLE_IRISH = "MIDDLE_IRISH",
  MIKMAQ = "MIKMAQ",
  MINANGKABAU = "MINANGKABAU",
  UNCODED_LANGUAGES = "UNCODED_LANGUAGES",
  MON_KHMER_LANGUAGES = "MON_KHMER_LANGUAGES",
  MALAGASY = "MALAGASY",
  MALTESE = "MALTESE",
  MANCHU = "MANCHU",
  MANIPURI = "MANIPURI",
  MANOBO_LANGUAGES = "MANOBO_LANGUAGES",
  MOHAWK = "MOHAWK",
  MONGOLIAN = "MONGOLIAN",
  MOSSI = "MOSSI",
  MULTIPLE_LANGUAGES = "MULTIPLE_LANGUAGES",
  MUNDA_LANGUAGES = "MUNDA_LANGUAGES",
  MUSCOGEE = "MUSCOGEE",
  MIRANDESE = "MIRANDESE",
  MARWARI = "MARWARI",
  BURMESE = "BURMESE",
  MAYAN_LANGUAGES = "MAYAN_LANGUAGES",
  ERZYA = "ERZYA",
  NAHUATL_LANGUAGES = "NAHUATL_LANGUAGES",
  NORTH_AMERICAN_INDIAN_LANGUAGES = "NORTH_AMERICAN_INDIAN_LANGUAGES",
  NEAPOLITAN = "NEAPOLITAN",
  NAURU = "NAURU",
  NAVAJO = "NAVAJO",
  SOUTH_NDEBELE = "SOUTH_NDEBELE",
  NORTH_NDEBELE = "NORTH_NDEBELE",
  NDONGA = "NDONGA",
  LOW_GERMAN = "LOW_GERMAN",
  NEPALI = "NEPALI",
  NEWARI = "NEWARI",
  NIAS = "NIAS",
  NIGER_KORDOFANIAN_LANGUAGES = "NIGER_KORDOFANIAN_LANGUAGES",
  NIUEAN = "NIUEAN",
  NORWEGIAN_NYNORSK = "NORWEGIAN_NYNORSK",
  NORWEGIAN_BOKMAL = "NORWEGIAN_BOKMAL",
  NOGAI = "NOGAI",
  OLD_NORSE = "OLD_NORSE",
  NKO = "NKO",
  NORTHERN_SOTHO = "NORTHERN_SOTHO",
  NUBIAN_LANGUAGES = "NUBIAN_LANGUAGES",
  CLASSICAL_NEWARI = "CLASSICAL_NEWARI",
  NYANJA = "NYANJA",
  NYAMWEZI = "NYAMWEZI",
  NYANKOLE = "NYANKOLE",
  NYORO = "NYORO",
  NZIMA = "NZIMA",
  OCCITAN = "OCCITAN",
  OJIBWA = "OJIBWA",
  ODIA = "ODIA",
  OROMO = "OROMO",
  OSAGE = "OSAGE",
  OSSETIC = "OSSETIC",
  OTTOMAN_TURKISH = "OTTOMAN_TURKISH",
  OTOMIAN_LANGUAGES = "OTOMIAN_LANGUAGES",
  PAPUAN_LANGUAGES = "PAPUAN_LANGUAGES",
  PANGASINAN = "PANGASINAN",
  PAHLAVI = "PAHLAVI",
  PAMPANGA = "PAMPANGA",
  PUNJABI = "PUNJABI",
  PAPIAMENTO = "PAPIAMENTO",
  PALAUAN = "PALAUAN",
  OLD_PERSIAN = "OLD_PERSIAN",
  PHILIPPINE_LANGUAGES = "PHILIPPINE_LANGUAGES",
  PHOENICIAN = "PHOENICIAN",
  PALI = "PALI",
  POHNPEIAN = "POHNPEIAN",
  PRAKRIT_LANGUAGES = "PRAKRIT_LANGUAGES",
  OLD_PROVENCAL = "OLD_PROVENCAL",
  PASHTO = "PASHTO",
  QUECHUA = "QUECHUA",
  RAJASTHANI = "RAJASTHANI",
  RAPANUI = "RAPANUI",
  RAROTONGAN = "RAROTONGAN",
  ROMANCE_LANGUAGES = "ROMANCE_LANGUAGES",
  ROMANSH = "ROMANSH",
  ROMANY = "ROMANY",
  RUNDI = "RUNDI",
  AROMANIAN = "AROMANIAN",
  SANDAWE = "SANDAWE",
  SANGO = "SANGO",
  SAKHA = "SAKHA",
  SOUTH_AMERICAN_INDIAN_LANGUAGES = "SOUTH_AMERICAN_INDIAN_LANGUAGES",
  SALISHAN_LANGUAGES = "SALISHAN_LANGUAGES",
  SAMARITAN_ARAMAIC = "SAMARITAN_ARAMAIC",
  SANSKRIT = "SANSKRIT",
  SASAK = "SASAK",
  SANTALI = "SANTALI",
  SICILIAN = "SICILIAN",
  SCOTS = "SCOTS",
  SELKUP = "SELKUP",
  SEMITIC_LANGUAGES = "SEMITIC_LANGUAGES",
  OLD_IRISH = "OLD_IRISH",
  SIGN_LANGUAGES = "SIGN_LANGUAGES",
  SHAN = "SHAN",
  SIDAMO = "SIDAMO",
  SINHALA = "SINHALA",
  SIOUAN_LANGUAGES = "SIOUAN_LANGUAGES",
  SINO_TIBETAN_LANGUAGES = "SINO_TIBETAN_LANGUAGES",
  SLAVIC_LANGUAGES = "SLAVIC_LANGUAGES",
  SLOVAK = "SLOVAK",
  SLOVENIAN = "SLOVENIAN",
  SOUTHERN_SAMI = "SOUTHERN_SAMI",
  NORTHERN_SAMI = "NORTHERN_SAMI",
  SAMI_LANGUAGES = "SAMI_LANGUAGES",
  LULE_SAMI = "LULE_SAMI",
  INARI_SAMI = "INARI_SAMI",
  SAMOAN = "SAMOAN",
  SKOLT_SAMI = "SKOLT_SAMI",
  SHONA = "SHONA",
  SINDHI = "SINDHI",
  SONINKE = "SONINKE",
  SOGDIEN = "SOGDIEN",
  SOMALI = "SOMALI",
  SONGHAI_LANGUAGES = "SONGHAI_LANGUAGES",
  SOUTHERN_SOTHO = "SOUTHERN_SOTHO",
  ALBANIAN = "ALBANIAN",
  SARDINIAN = "SARDINIAN",
  SRANAN_TONGO = "SRANAN_TONGO",
  SERBIAN = "SERBIAN",
  NILO_SAHARAN_LANGUAGES = "NILO_SAHARAN_LANGUAGES",
  SWATI = "SWATI",
  SUKUMA = "SUKUMA",
  SUNDANESE = "SUNDANESE",
  SUSU = "SUSU",
  SUMERIAN = "SUMERIAN",
  SWAHILI = "SWAHILI",
  CLASSICAL_SYRIAC = "CLASSICAL_SYRIAC",
  SYRIAC = "SYRIAC",
  TAHITIAN = "TAHITIAN",
  TAI_LANGUAGES = "TAI_LANGUAGES",
  TAMIL = "TAMIL",
  TATAR = "TATAR",
  TELUGU = "TELUGU",
  TIMNE = "TIMNE",
  TERENO = "TERENO",
  TETUM = "TETUM",
  TAJIK = "TAJIK",
  TIBETAN = "TIBETAN",
  TIGRE = "TIGRE",
  TIGRINYA = "TIGRINYA",
  TIV = "TIV",
  TOKELAU = "TOKELAU",
  KLINGON = "KLINGON",
  TLINGIT = "TLINGIT",
  TAMASHEK = "TAMASHEK",
  NYASA_TONGA = "NYASA_TONGA",
  TONGAN = "TONGAN",
  TOK_PISIN = "TOK_PISIN",
  TSIMSHIAN = "TSIMSHIAN",
  TSWANA = "TSWANA",
  TSONGA = "TSONGA",
  TURKMEN = "TURKMEN",
  TUMBUKA = "TUMBUKA",
  TUPI_LANGUAGES = "TUPI_LANGUAGES",
  ALTAIC_LANGUAGES = "ALTAIC_LANGUAGES",
  TUVALU = "TUVALU",
  TWI = "TWI",
  TUVINIAN = "TUVINIAN",
  UDMURT = "UDMURT",
  UGARITIC = "UGARITIC",
  UYGHUR = "UYGHUR",
  UMBUNDU = "UMBUNDU",
  UNKNOWN_LANGUAGE = "UNKNOWN_LANGUAGE",
  UZBEK = "UZBEK",
  VAI = "VAI",
  VENDA = "VENDA",
  VOLAPUK = "VOLAPUK",
  VOTIC = "VOTIC",
  WAKASHAN_LANGUAGES = "WAKASHAN_LANGUAGES",
  WOLAYTTA = "WOLAYTTA",
  WARAY = "WARAY",
  WASHO = "WASHO",
  WELSH = "WELSH",
  SORBIAN_LANGUAGES = "SORBIAN_LANGUAGES",
  WALLOON = "WALLOON",
  WOLOF = "WOLOF",
  KALMYK = "KALMYK",
  XHOSA = "XHOSA",
  YAO = "YAO",
  YAPESE = "YAPESE",
  YIDDISH = "YIDDISH",
  YORUBA = "YORUBA",
  YUPIK_LANGUAGES = "YUPIK_LANGUAGES",
  ZAPOTEC = "ZAPOTEC",
  BLISSYMBOLS = "BLISSYMBOLS",
  ZENAGA = "ZENAGA",
  STANDARD_MOROCCAN_TAMAZIGHT = "STANDARD_MOROCCAN_TAMAZIGHT",
  ZHUANG = "ZHUANG",
  ZANDE_LANGUAGES = "ZANDE_LANGUAGES",
  ZULU = "ZULU",
  ZUNI = "ZUNI",
  ZAZA = "ZAZA",
  PRIMARY_LANGUAGE_OTHER = "PRIMARY_LANGUAGE_OTHER",
  TURKISH = "TURKISH",
  NORWEGIAN = "NORWEGIAN",
  AFRIKAANS = "AFRIKAANS",
  ARMENIAN = "ARMENIAN",
  AZERBAIJANI = "AZERBAIJANI",
  MALAY = "MALAY",
  SWEDISH = "SWEDISH",
  THAI = "THAI",
}

export enum InstanceEligibilityCriteriaInsuranceKeywordEnum {
  INSURANCE_KEYWORD_ENUM_UNSPECIFIED = "INSURANCE_KEYWORD_ENUM_UNSPECIFIED",
  ANY = "ANY",
  SKIP_LOOKUP = "SKIP_LOOKUP",
}

export enum CernerConfigFHIRAuth {
  AUTH_UNSPECIFIED = "AUTH_UNSPECIFIED",
  OAUTH2 = "OAUTH2",
  BASIC = "BASIC",
}

export enum EpicConfigHL7ConfigValueType {
  CONFIG_VALUE_TYPE_UNSPECIFIED = "CONFIG_VALUE_TYPE_UNSPECIFIED",
  ENVIRONMENTAL_VARIABLE = "ENVIRONMENTAL_VARIABLE",
  LITERAL = "LITERAL",
}

export enum EpicConfigHL7Oauth2Workflow {
  WORKFLOW_UNSPECIFIED = "WORKFLOW_UNSPECIFIED",
  RESOURCE_OWNER_PASSWORD_CREDENTIALS = "RESOURCE_OWNER_PASSWORD_CREDENTIALS",
}

export enum EpicConfigHL7HttpsAuth {
  AUTH_UNSPECIFIED = "AUTH_UNSPECIFIED",
  OAUTH2 = "OAUTH2",
}

export enum SyncAppointmentRequestAppointmentType {
  APPOINTMENT_TYPE_UNSPECIFIED = "APPOINTMENT_TYPE_UNSPECIFIED",
  ENROLLMENT = "ENROLLMENT",
}

export enum SyncAppointmentRequestSyncAction {
  SYNC_ACTION_UNSPECIFIED = "SYNC_ACTION_UNSPECIFIED",
  SCHEDULE = "SCHEDULE",
  RESCHEDULE = "RESCHEDULE",
  CLOSE = "CLOSE",
  CANCEL = "CANCEL",
  NO_SHOW = "NO_SHOW",
}

export enum PatientInfoGender {
  GENDER_UNSPECIFIED = "GENDER_UNSPECIFIED",
  FEMALE = "FEMALE",
  MALE = "MALE",
  OTHER = "OTHER",
}

export enum SendHL7V2MessageRequestInterfaceType {
  INTERFACE_TYPE_UNSPECIFIED = "INTERFACE_TYPE_UNSPECIFIED",
  SCHEDULING = "SCHEDULING",
  SCANNED_DOCUMENT = "SCANNED_DOCUMENT",
  TRANSCRIPTIONS = "TRANSCRIPTIONS",
  FINANCIAL_TRANSACTIONS = "FINANCIAL_TRANSACTIONS",
  ORDERS = "ORDERS",
  FLOWSHEETS = "FLOWSHEETS",
}

export enum SendHL7V2MessageRequestMessageType {
  MESSAGE_TYPE_UNSPECIFIED = "MESSAGE_TYPE_UNSPECIFIED",
  BILLING_CHARGE = "BILLING_CHARGE",
  BILLING_NOTE = "BILLING_NOTE",
  BILLING_SUMMARY = "BILLING_SUMMARY",
  PATIENT_CONSENTS = "PATIENT_CONSENTS",
  PATIENT_NOTE = "PATIENT_NOTE",
  PATIENT_SUMMARY = "PATIENT_SUMMARY",
  APPOINTMENT = "APPOINTMENT",
  ORDER = "ORDER",
  FLOWSHEET_ENTRY = "FLOWSHEET_ENTRY",
}

export enum OutboundAthenaMessageAthenaMessageTypeEnum {
  ATHENA_MESSAGE_TYPE_ENUM_UNSPECIFIED = "ATHENA_MESSAGE_TYPE_ENUM_UNSPECIFIED",
  BILLING_SUMMARY = "BILLING_SUMMARY",
  PATIENT_CONSENTS = "PATIENT_CONSENTS",
  PATIENT_SUMMARY = "PATIENT_SUMMARY",
  PATIENT_NOTE = "PATIENT_NOTE",
}

export enum OutboundAthenaMessageAthenaMessageStatusEnum {
  ATHENA_MESSAGE_STATUS_ENUM_UNSPECIFIED = "ATHENA_MESSAGE_STATUS_ENUM_UNSPECIFIED",
  SEND_SUCCEEDED = "SEND_SUCCEEDED",
  SEND_FAILED = "SEND_FAILED",
}

export enum InstanceCampaignCampaignType {
  CAMPAIGN_TYPE_UNSPECIFIED = "CAMPAIGN_TYPE_UNSPECIFIED",
  SMS = "SMS",
  EMAIL = "EMAIL",
  CADENCE = "CADENCE",
}

export enum InstanceCampaignCampaignName {
  CAMPAIGN_NAME_UNSPECIFIED = "CAMPAIGN_NAME_UNSPECIFIED",
  FIRST_PATIENT_ACTIVATION = "FIRST_PATIENT_ACTIVATION",
  FIRST_PATIENT_ENROLLMENT = "FIRST_PATIENT_ENROLLMENT",
  FIRST_PATIENT_ALERT = "FIRST_PATIENT_ALERT",
}

export type RetrieveAllPatientEhrInfoRequest = {
  mrn?: string
  practiceId?: string
}

export type RetrieveAllPatientEhrInfoResponse = {
  patientEhrInfo?: AllPatientEhrInfo[]
}

export type AllPatientEhrInfo = {
  isPrimary?: boolean
  mrn?: string
  departmentId?: GoogleProtobufWrappers.StringValue
  departmentDisplayName?: GoogleProtobufWrappers.StringValue
}

export type GetInstanceRequest = {
  name?: string
}

export type ListInstancesRequest = {
  pageSize?: number
  pageToken?: string
  readMask?: GoogleProtobufField_mask.FieldMask
  filter?: string
  orderBy?: string
}

export type ListInstancesResponse = {
  instances?: Instance[]
  nextPageToken?: string
  totalSize?: number
}

export type ListInstanceHistoriesRequest = {
  parent?: string
  pageSize?: number
  pageToken?: string
  readMask?: GoogleProtobufField_mask.FieldMask
  filter?: string
  orderBy?: string
}

export type ListInstanceHistoriesResponse = {
  instanceHistories?: InstanceHistory[]
  nextPageToken?: string
  totalSize?: number
}

export type CreateInstanceRequest = {
  instance?: Instance
}

export type UpdateInstanceRequest = {
  instance?: Instance
  updateMask?: GoogleProtobufField_mask.FieldMask
  reason?: string
}

export type InstanceEligibilityCriteriaAgeLimits = {
  minimumAge?: number
  maximumAge?: number
  minimumAgeChf?: number
  maximumAgeChf?: number
  minimumAgeHtn?: number
  maximumAgeHtn?: number
  minimumAgeT2d?: number
  maximumAgeT2d?: number
}

export type InstanceEligibilityCriteria = {
  allowedInsurances?: string[]
  ageLimits?: InstanceEligibilityCriteriaAgeLimits
}

export type CernerConfigTenantInfo = {
  tenantName?: string
  tenantId?: string
}

export type CernerConfigFHIRNoteConfigEntry = {
  authorFhirId?: string
  codesetCode?: string
}

export type CernerConfigFHIRNoteConfig = {
  codesetSystem?: string
  clinicalNote?: CernerConfigFHIRNoteConfigEntry
  billingSummary?: CernerConfigFHIRNoteConfigEntry
  patientSummary?: CernerConfigFHIRNoteConfigEntry
  patientConsent?: CernerConfigFHIRNoteConfigEntry
  patientDisenrollment?: CernerConfigFHIRNoteConfigEntry
}

export type CernerConfigFHIREncounterConfig = {
  automaticEncounterCreation?: boolean
  encounterCodesetSystem?: string
  encounterCodesetCode?: string
  encounterLocationFhirId?: string
  enableAutomaticEncounterLookup?: boolean
}

export type CernerConfigFHIRRequestConfig = {
  countPerPage?: number
  maxPages?: number
  timeoutSeconds?: number
  batchSize?: number
}

export type CernerConfigFHIROrderConfigOrderEntry = {
  codesetCode?: string
  codesetSystem?: string
}

export type CernerConfigFHIROrderConfig = {
  rpmOrder?: CernerConfigFHIROrderConfigOrderEntry
}

export type CernerConfigFHIR = {
  url?: string
  oid?: {[key: string]: string}
  lookupMapping?: {[key: string]: string}
  auth?: CernerConfigFHIRAuth
  noteConfig?: CernerConfigFHIRNoteConfig
  requestConfig?: CernerConfigFHIRRequestConfig
  orderConfig?: CernerConfigFHIROrderConfig
  encounterConfig?: CernerConfigFHIREncounterConfig
}

export type CernerConfigHL7 = {
  ordersRpmServiceId?: string
  ordersMrnCode?: string
  ordersProviderIdCode?: string
  mrnCodeInstanceMapping?: {[key: string]: number}
}

export type CernerConfig = {
  fhir?: CernerConfigFHIR
  hl7?: CernerConfigHL7
  name?: string
  cernerVersion?: string
  tenant?: CernerConfigTenantInfo
  eligibilityCriteria?: InstanceEligibilityCriteria
  includeAdditionalConditionDiagnosisCodes?: boolean
  allowBillingThroughAthena?: boolean
}

export type EpicConfigFHIROrderConfigOrderEntry = {
  codesetCode?: string
  codesetSystem?: string
}

export type EpicConfigFHIROrderConfig = {
  rpmOrder?: EpicConfigFHIROrderConfigOrderEntry
}

export type EpicConfigFHIR = {
  url?: string
  oid?: {[key: string]: string}
  lookupMapping?: {[key: string]: string}
  orderConfig?: EpicConfigFHIROrderConfig
}

export type EpicConfigHL7Endpoint = {
  url?: string
  docType?: {[key: string]: string}
  customFields?: {[key: string]: string}
}

export type EpicConfigHL7ConfigValue = {
  type?: EpicConfigHL7ConfigValueType
  value?: string
}

export type EpicConfigHL7Oauth2 = {
  workflow?: EpicConfigHL7Oauth2Workflow
  clientId?: EpicConfigHL7ConfigValue
  clientSecret?: EpicConfigHL7ConfigValue
  username?: EpicConfigHL7ConfigValue
  password?: EpicConfigHL7ConfigValue
  tokenUri?: EpicConfigHL7ConfigValue
  authUri?: EpicConfigHL7ConfigValue
}

export type EpicConfigHL7Https = {
  auth?: EpicConfigHL7HttpsAuth
  oauth2?: EpicConfigHL7Oauth2
}

export type EpicConfigHL7ValidationConfigEntry = {
  messageType?: SendHL7V2MessageRequestMessageType
  expectedInterfaceType?: SendHL7V2MessageRequestInterfaceType
  waitMinutes?: string
  maxAttemptCount?: string
  shouldRetry?: boolean
  maxRetryCount?: string
  disableValidation?: boolean
  documentInformationType?: string
  disableAlertLog?: boolean
}

export type EpicConfigHL7ValidationConfig = {
  entries?: EpicConfigHL7ValidationConfigEntry[]
}

export type EpicConfigHL7 = {
  customFields?: {[key: string]: string}
  scannedDocEndpoint?: EpicConfigHL7Endpoint
  transcriptionsEndpoint?: EpicConfigHL7Endpoint
  financialTxnEndpoint?: EpicConfigHL7Endpoint
  ordersEndpoint?: EpicConfigHL7Endpoint
  vpnName?: string
  schedulingEndpoint?: EpicConfigHL7Endpoint
  ordersRpmServiceId?: string
  ordersMrnCode?: string
  generateClaimsEncounters?: boolean
  receiveEnterpriseMrn?: boolean
  https?: EpicConfigHL7Https
  validationConfig?: EpicConfigHL7ValidationConfig
  outboundUseShortDateFormat?: boolean
  ordersProviderIdCode?: string
  flowsheetsEndpoint?: EpicConfigHL7Endpoint
}

export type EpicConfigDepartmentMappingMappingEntry = {
  departmentId?: string
  externalId?: string
}

export type EpicConfigDepartmentMapping = {
  useExternalIdOutgoing?: boolean
  canUseRawIdIncoming?: boolean
  mappings?: EpicConfigDepartmentMappingMappingEntry[]
}

export type EpicConfig = {
  fhir?: EpicConfigFHIR
  hl7?: EpicConfigHL7
  name?: string
  eligibilityCriteria?: InstanceEligibilityCriteria
  epicVersion?: string
  allowedJkuDomain?: string
  includeCsn?: boolean
  departmentMapping?: EpicConfigDepartmentMapping
  includeBillingEncounterCsn?: boolean
  allowBpa?: boolean
  includeEobDiagnosisCodes?: boolean
  selectedBillingDepartmentId?: string
  useScannedDocNotes?: boolean
  allowAdditionalBillingEncounterPerMonth?: boolean
  includeAdditionalConditionDiagnosisCodes?: boolean
  generateClaimsVitalsEncounters?: boolean
  cadenceEpicAppVersion?: string
  generateBillingExternalDocumentId?: boolean
  genericSer?: string
  cpoeUsesNpi?: boolean
  cpoeIncludesDepartmentId?: boolean
  generateStatusChangeEncounters?: boolean
  allowInstantOrders?: boolean
  excludeEdVisitBps?: boolean
  cdsHookConditionActionCodes?: {[key: string]: string}
  cdsHookResponseIndicator?: string
  generateEligibleStatusChangeEncounters?: boolean
}

export type AthenaConfig = {
  practiceId?: string
  resourceScopes?: EMRResourceType[]
  eligibilityCriteria?: InstanceEligibilityCriteria
}


type BaseInstance = {
  name?: string
  instanceId?: number
  healthSystemId?: number
  displayName?: string
  athenaConfig?: AthenaConfig
  etag?: string
  createTime?: GoogleProtobufTimestamp.Timestamp
  updateTime?: GoogleProtobufTimestamp.Timestamp
  epicCdsHookId?: string
  epicCdsHookActionCode?: string
  epicOrdersAppIssuer?: string
  epicOrdersAppIssuerLaunch?: string
  ehr?: CadenceModelsV1Models.EHR
  xealthDeployment?: string
}

export type Instance = BaseInstance
  & OneOf<{ epicConfig: EpicConfig; cernerConfig: CernerConfig }>

export type InstanceHistory = {
  name?: string
  createTime?: GoogleProtobufTimestamp.Timestamp
  createUser?: string
  reason?: string
  instanceJson?: GoogleProtobufStruct.Struct
  instanceVersion?: string
}

export type SyncAppointmentRequest = {
  appointmentId?: string
  hospital?: HospitalInfo
  provider?: ProviderInfo
  patient?: PatientInfo
  appointmentType?: SyncAppointmentRequestAppointmentType
  appointmentDateTime?: GoogleProtobufTimestamp.Timestamp
  appointmentDuration?: number
  action?: SyncAppointmentRequestSyncAction
}

export type HospitalInfo = {
  hospitalId?: string
  ehr?: CadenceModelsV1Models.EHR
  athenaPracticeId?: string
  athenaDepartmentId?: string
  epicFhirEndpointUri?: string
  epicSchedulingUri?: string
  epicDepartment?: string
  epicDepartmentId?: string
  vpnName?: string
  instanceId?: number
  timeZone?: string
  epicDepartmentAbbr?: string
}

export type ProviderInfo = {
  providerId?: string
  familyName?: string
  givenName?: string
  npi?: string
  athenaId?: string
  epicId?: string
  epicFhirId?: string
}

export type PatientInfo = {
  patientId?: string
  familyName?: string
  givenName?: string
  mrn?: string
  epicId?: string
  epicFhirId?: string
  dob?: GoogleTypeDate.Date
  gender?: PatientInfoGender
}

export type AppointmentResponse = {
  appointmentId?: string
}

export type AthenaOrderEvents = {
  athenaPracticeId?: string
  queryTime?: GoogleProtobufTimestamp.Timestamp
  payload?: string
}

export type SendHL7V2MessageRequest = {
  instanceId?: number
  interfaceType?: SendHL7V2MessageRequestInterfaceType
  payload?: string
  messageType?: SendHL7V2MessageRequestMessageType
  metadataParameters?: HL7MetadataParameters
}

export type SendHL7V2MessageResponse = {
  payload?: string
}

export type SendCPOEMessageRequest = {
  name?: string
  patientId?: string
  orderingProviderProvid?: string
}

export type SendCPOEMessageResponse = {
  payload?: string
}

export type RetryHL7MessageRequest = {
  instanceId?: number
  messageId?: string
}

export type RetryHL7MessageResponse = {
  payload?: string
}

export type ValidateHL7MessageRequest = {
  instanceId?: number
  messageId?: string
}

export type ValidateHL7MessageResponse = {
  success?: boolean
  skipped?: boolean
}

export type HL7MetadataParameters = {
  patientCadenceId?: string
  patientMrn?: string
  patientFhirId?: string
  patientEpicId?: string
  providerCadenceId?: string
  providerId?: string
  providerNpi?: string
  providerFhirId?: string
  providerEpicId?: string
  startDateTime?: GoogleProtobufTimestamp.Timestamp
  endDateTime?: GoogleProtobufTimestamp.Timestamp
  durationMinutes?: string
  noteId?: string
  actionableNote?: boolean
}

export type HL7OrderExpectedSourceEntry = {
  name?: string
  instanceId?: number
  mrn?: string
  pendedStatus?: boolean
  source?: string
  externalOrderId?: string
  npi?: string
  externalProviderId?: string
  encounterFhirId?: string
  createTime?: GoogleProtobufTimestamp.Timestamp
  updateTime?: GoogleProtobufTimestamp.Timestamp
}

export type CreateHL7OrderExpectedSourceEntryRequest = {
  hl7OrderExpectedSource?: HL7OrderExpectedSourceEntry
}

export type UpdateHL7OrderExpectedSourceEntryRequest = {
  hl7OrderExpectedSource?: HL7OrderExpectedSourceEntry
  updateMask?: GoogleProtobufField_mask.FieldMask
}

export type ListHL7OrderExpectedSourceEntriesRequest = {
  parent?: string
  patientMrn?: string
  pageSize?: number
  pageToken?: string
}

export type ListHL7OrderExpectedSourceEntriesResponse = {
  hl7OrderExpectedSources?: HL7OrderExpectedSourceEntry[]
  nextPageToken?: string
  totalSize?: number
}

export type CreateConsentRequest = {
  consent?: Consent
}

export type Consent = {
  patientId?: string
  healthSystemId?: number
  ehrType?: CadenceModelsV1Models.EHR
  hospitalId?: string
  providerAndTitle?: string
  enroller?: string
  patientFirst?: string
  patientLast?: string
  patientAddressStreetAddress?: string
  patientAddressPremise?: string
  patientAddressCity?: string
  patientAddressState?: string
  patientAddressPostalCode?: string
  consentTime?: GoogleProtobufTimestamp.Timestamp
  patientDobTime?: GoogleProtobufTimestamp.Timestamp
  marketId?: string
  state?: string
  legalRepName?: string
  legalRepRelationship?: string
  interpreterName?: string
  patientEmail?: string
  primaryLanguage?: PrimaryLanguage
  programType?: ProgramType
}

export type OutboundAthenaMessage = {
  name?: string
  createTime?: GoogleProtobufTimestamp.Timestamp
  updateTime?: GoogleProtobufTimestamp.Timestamp
  messageType?: OutboundAthenaMessageAthenaMessageTypeEnum
  messageStatus?: OutboundAthenaMessageAthenaMessageStatusEnum
  payload?: string
  patientId?: string
  actionable?: boolean
  mrnUsed?: string
  mrnOnRecord?: string
  athenaDepartmentId?: string
  athenaPracticeId?: string
}

export type CreateOutboundAthenaMessageRequest = {
  outboundAthenaMessage?: OutboundAthenaMessage
}

export type UpdateOutboundAthenaMessageRequest = {
  outboundAthenaMessage?: OutboundAthenaMessage
  updateMask?: GoogleProtobufField_mask.FieldMask
}

export type ListOutboundAthenaMessagesRequest = {
  pageSize?: number
  pageToken?: string
}

export type ListOutboundAthenaMessagesResponse = {
  outboundAthenaMessages?: OutboundAthenaMessage[]
  nextPageToken?: string
  totalSize?: number
}

export type ListInstanceCampaignsRequest = {
  parent?: string
  pageSize?: number
  pageToken?: string
  readMask?: GoogleProtobufField_mask.FieldMask
  filter?: string
  orderBy?: string
}

export type ListInstanceCampaignsResponse = {
  instanceCampaigns?: InstanceCampaign[]
  nextPageToken?: string
  totalSize?: number
}

export type InstanceCampaign = {
  name?: string
  instanceId?: string
  campaignType?: InstanceCampaignCampaignType
  campaignName?: InstanceCampaignCampaignName
  enabled?: boolean
  createTime?: GoogleProtobufTimestamp.Timestamp
  updateTime?: GoogleProtobufTimestamp.Timestamp
  createUser?: string
  updateUser?: string
}

export type EnableInstanceCampaignRequest = {
  parent?: string
  campaignId?: string
}

export type DisableInstanceCampaignRequest = {
  parent?: string
  campaignId?: string
}

export type BulkDeleteOutboundEhrMessagesRequest = {
  parent?: string
}

export type Patient = {
  name?: string
}

export class EHRService {
  static SyncAppointment(req: SyncAppointmentRequest, initReq?: fm.InitReq): Promise<AppointmentResponse> {
    return fm.fetchReq<SyncAppointmentRequest, AppointmentResponse>(`/.EHRService/SyncAppointment`, {...initReq, method: "POST", body: JSON.stringify(req, fm.replacer)})
  }
  static CreateInstance(req: CreateInstanceRequest, initReq?: fm.InitReq): Promise<Instance> {
    return fm.fetchReq<CreateInstanceRequest, Instance>(`/ehr/v2/instances`, {...initReq, method: "POST", body: JSON.stringify(req["instance"], fm.replacer)})
  }
  static GetInstance(req: GetInstanceRequest, initReq?: fm.InitReq): Promise<Instance> {
    return fm.fetchReq<GetInstanceRequest, Instance>(`/ehr/v2/${req["name"]}?${fm.renderURLSearchParams(req, ["name"])}`, {...initReq, method: "GET"})
  }
  static ListInstances(req: ListInstancesRequest, initReq?: fm.InitReq): Promise<ListInstancesResponse> {
    return fm.fetchReq<ListInstancesRequest, ListInstancesResponse>(`/ehr/v2/instances?${fm.renderURLSearchParams(req, [])}`, {...initReq, method: "GET"})
  }
  static ListInstanceHistories(req: ListInstanceHistoriesRequest, initReq?: fm.InitReq): Promise<ListInstanceHistoriesResponse> {
    return fm.fetchReq<ListInstanceHistoriesRequest, ListInstanceHistoriesResponse>(`/ehr/v2/${req["parent"]}/histories?${fm.renderURLSearchParams(req, ["parent"])}`, {...initReq, method: "GET"})
  }
  static UpdateInstance(req: UpdateInstanceRequest, initReq?: fm.InitReq): Promise<Instance> {
    return fm.fetchReq<UpdateInstanceRequest, Instance>(`/ehr/v2/${req["instance"]?.["name"]}`, {...initReq, method: "PATCH", body: JSON.stringify(req["instance"], fm.replacer)})
  }
  static SendHL7V2Message(req: SendHL7V2MessageRequest, initReq?: fm.InitReq): Promise<SendHL7V2MessageResponse> {
    return fm.fetchReq<SendHL7V2MessageRequest, SendHL7V2MessageResponse>(`/.EHRService/SendHL7V2Message`, {...initReq, method: "POST", body: JSON.stringify(req, fm.replacer)})
  }
  static RetryHL7Message(req: RetryHL7MessageRequest, initReq?: fm.InitReq): Promise<RetryHL7MessageResponse> {
    return fm.fetchReq<RetryHL7MessageRequest, RetryHL7MessageResponse>(`/.EHRService/RetryHL7Message`, {...initReq, method: "POST", body: JSON.stringify(req, fm.replacer)})
  }
  static ValidateHL7Message(req: ValidateHL7MessageRequest, initReq?: fm.InitReq): Promise<ValidateHL7MessageResponse> {
    return fm.fetchReq<ValidateHL7MessageRequest, ValidateHL7MessageResponse>(`/.EHRService/ValidateHL7Message`, {...initReq, method: "POST", body: JSON.stringify(req, fm.replacer)})
  }
  static SendCPOEMessage(req: SendCPOEMessageRequest, initReq?: fm.InitReq): Promise<SendCPOEMessageResponse> {
    return fm.fetchReq<SendCPOEMessageRequest, SendCPOEMessageResponse>(`/ehr/v2/${req["name"]}:sendCpoeMessage`, {...initReq, method: "POST", body: JSON.stringify(req, fm.replacer)})
  }
  static CreateConsent(req: CreateConsentRequest, initReq?: fm.InitReq): Promise<Consent> {
    return fm.fetchReq<CreateConsentRequest, Consent>(`/ehr/v2/consent`, {...initReq, method: "POST", body: JSON.stringify(req["consent"], fm.replacer)})
  }
  static CreateOutboundAthenaMessage(req: CreateOutboundAthenaMessageRequest, initReq?: fm.InitReq): Promise<OutboundAthenaMessage> {
    return fm.fetchReq<CreateOutboundAthenaMessageRequest, OutboundAthenaMessage>(`/ehr/v2/outbound/athena/messages`, {...initReq, method: "POST", body: JSON.stringify(req["outbound_athena_message"], fm.replacer)})
  }
  static UpdateOutboundAthenaMessage(req: UpdateOutboundAthenaMessageRequest, initReq?: fm.InitReq): Promise<OutboundAthenaMessage> {
    return fm.fetchReq<UpdateOutboundAthenaMessageRequest, OutboundAthenaMessage>(`/ehr/v2/outbound/athena/${req["outboundAthenaMessage"]?.["name"]}`, {...initReq, method: "PATCH", body: JSON.stringify(req["outbound_athena_message"], fm.replacer)})
  }
  static ListOutboundAthenaMessages(req: ListOutboundAthenaMessagesRequest, initReq?: fm.InitReq): Promise<ListOutboundAthenaMessagesResponse> {
    return fm.fetchReq<ListOutboundAthenaMessagesRequest, ListOutboundAthenaMessagesResponse>(`/ehr/v2/outbound/athena/messages?${fm.renderURLSearchParams(req, [])}`, {...initReq, method: "GET"})
  }
  static ListInstanceCampaigns(req: ListInstanceCampaignsRequest, initReq?: fm.InitReq): Promise<ListInstanceCampaignsResponse> {
    return fm.fetchReq<ListInstanceCampaignsRequest, ListInstanceCampaignsResponse>(`/ehr/v2/${req["parent"]}/campaigns?${fm.renderURLSearchParams(req, ["parent"])}`, {...initReq, method: "GET"})
  }
  static EnableInstanceCampaign(req: EnableInstanceCampaignRequest, initReq?: fm.InitReq): Promise<GoogleProtobufEmpty.Empty> {
    return fm.fetchReq<EnableInstanceCampaignRequest, GoogleProtobufEmpty.Empty>(`/ehr/v2/${req["parent"]}/campaigns/${req["campaignId"]}:enable`, {...initReq, method: "POST", body: JSON.stringify(req, fm.replacer)})
  }
  static DisableInstanceCampaign(req: DisableInstanceCampaignRequest, initReq?: fm.InitReq): Promise<GoogleProtobufEmpty.Empty> {
    return fm.fetchReq<DisableInstanceCampaignRequest, GoogleProtobufEmpty.Empty>(`/ehr/v2/${req["parent"]}/campaigns/${req["campaignId"]}:disable`, {...initReq, method: "POST", body: JSON.stringify(req, fm.replacer)})
  }
  static RetrieveAllPatientEhrInfo(req: RetrieveAllPatientEhrInfoRequest, initReq?: fm.InitReq): Promise<RetrieveAllPatientEhrInfoResponse> {
    return fm.fetchReq<RetrieveAllPatientEhrInfoRequest, RetrieveAllPatientEhrInfoResponse>(`/ehr/v2/patientsEhrInfo:retrieveAllPatientEhrInfo?${fm.renderURLSearchParams(req, [])}`, {...initReq, method: "GET"})
  }
  static BulkDeleteOutboundEhrMessages(req: BulkDeleteOutboundEhrMessagesRequest, initReq?: fm.InitReq): Promise<GoogleProtobufEmpty.Empty> {
    return fm.fetchReq<BulkDeleteOutboundEhrMessagesRequest, GoogleProtobufEmpty.Empty>(`/ehr/v2/${req["parent"]}/outboundMessages:bulkDelete`, {...initReq, method: "POST", body: JSON.stringify(req, fm.replacer)})
  }
  static CreateHL7OrderExpectedSourceEntry(req: CreateHL7OrderExpectedSourceEntryRequest, initReq?: fm.InitReq): Promise<HL7OrderExpectedSourceEntry> {
    return fm.fetchReq<CreateHL7OrderExpectedSourceEntryRequest, HL7OrderExpectedSourceEntry>(`/ehr/v2/hl7/order/expectedSources`, {...initReq, method: "POST", body: JSON.stringify(req["hl7_order_expected_source"], fm.replacer)})
  }
  static UpdateHL7OrderExpectedSourceEntry(req: UpdateHL7OrderExpectedSourceEntryRequest, initReq?: fm.InitReq): Promise<HL7OrderExpectedSourceEntry> {
    return fm.fetchReq<UpdateHL7OrderExpectedSourceEntryRequest, HL7OrderExpectedSourceEntry>(`/ehr/v2/${req["hl7OrderExpectedSource"]?.["name"]}`, {...initReq, method: "PATCH", body: JSON.stringify(req["hl7_order_expected_source"], fm.replacer)})
  }
  static ListHL7OrderExpectedSourceEntries(req: ListHL7OrderExpectedSourceEntriesRequest, initReq?: fm.InitReq): Promise<ListHL7OrderExpectedSourceEntriesResponse> {
    return fm.fetchReq<ListHL7OrderExpectedSourceEntriesRequest, ListHL7OrderExpectedSourceEntriesResponse>(`/ehr/v2/${req["parent"]}/${req["patientMrn"]}/hl7/order/expectedSources?${fm.renderURLSearchParams(req, ["parent", "patientMrn"])}`, {...initReq, method: "GET"})
  }
}