import { useEffect } from 'react';

import { logger } from '@/logger';
import { logout } from '@/pages/auth/actionCreators';
import { ClientForceUpdate } from '@/shared/types/featureFlags.types';
import { store } from '@/store';

import { useFlags } from './useFlags';
import { usePrevious } from './usePreviousState';

export function useForceClientUpdate() {
  const { forceClientUpdate } = useFlags();
  const prevForceClientUpdate = usePrevious(forceClientUpdate);
  useEffect(() => {
    if (
      prevForceClientUpdate === ClientForceUpdate.None &&
      forceClientUpdate === ClientForceUpdate.Logout
    ) {
      logger.warn(
        `Forcing a user to be logged out due to 'forceClientUpdate' flag being switched from ${ClientForceUpdate.None} -> ${ClientForceUpdate.Logout}`,
      );
      store.dispatch(logout());
    }

    if (
      prevForceClientUpdate === ClientForceUpdate.None &&
      forceClientUpdate === ClientForceUpdate.Refresh
    ) {
      logger.warn(
        `Forcing a user's Cadence tab to be refreshed due to 'forceClientUpdate' flag being switched from ${ClientForceUpdate.None} -> ${ClientForceUpdate.Refresh}.`,
      );
      window.location.reload();
    }
  }, [forceClientUpdate, prevForceClientUpdate]);
}
