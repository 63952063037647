import { AsyncTitrationAsyncTitrationStatus as Status } from '@/shared/generated/grpcGateway/medication.pb';

import type { TitrationRecommendation } from './hooks';

const PENDING = [Status.INITIALLY_REVIEWED, Status.PATIENT_CONSENTED];

export function isAsyncTitrationPending(titration?: TitrationRecommendation) {
  if (!titration?.status) {
    return false;
  }

  return PENDING.includes(titration.status);
}
