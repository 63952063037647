import { useWizardStateContext } from '@/shared/common/Wizard/state';
import { useShouldMoveUpActionPlan } from '@/shared/common/Wizard/state/sectionUtils';

import { INIT_VISIT_SECTIONS } from '../../../metadata';
import { SharedReviewActionSteps } from '../../../shared/ReviewActionSteps';
import type { TActionStep } from '../../../shared/SetActionSteps';
import { initVisitPath } from '../../paths';
import { usePatientGoalText } from '../usePatientGoal';

export function ReviewActionSteps() {
  const patientGoalText = usePatientGoalText();
  const {
    wizardFormData: { data: stepData },
  } = useWizardStateContext();
  const actionSteps = (stepData[
    initVisitPath('/action-plan', '/set-action-steps')
  ]?.values?.actionSteps || []) as TActionStep[];

  const shouldMoveUpActionPlan = useShouldMoveUpActionPlan();

  return (
    <SharedReviewActionSteps
      sections={INIT_VISIT_SECTIONS}
      nextPath={
        shouldMoveUpActionPlan
          ? initVisitPath('/med-review', '/index')
          : initVisitPath('/scheduling', '/index')
      }
      actionSteps={actionSteps}
      patientGoalText={patientGoalText}
    />
  );
}
