import { isValid, parseISO } from 'date-fns';

import { useReferenceMedication } from '@/pages/patients/PatientMedications/referenceMedications.queries';
import type {
  AsyncTitration,
  AsyncTitrationMedicationWasNotTitratedReason,
  CareProviderDetails,
  MedicationChange,
  MedicationChangeFrequency,
  NextTitrationStep,
  ReferenceMedication,
  RxNorm,
} from '@/shared/generated/grpcGateway/medication.pb';
import { AsyncTitrationAsyncTitrationStatus } from '@/shared/generated/grpcGateway/medication.pb';
import type { NoteStatus } from '@/shared/generated/grpcGateway/note.pb';
import { useFlags } from '@/shared/hooks';

export type TitrationRecommendation = {
  id?: string;
  rxcui?: string;
  doseQuantity?: number;
  frequency?: MedicationChangeFrequency;
  vitalsCriteria?: string;
  labsCriteria?: string;
  symptomsCriteria?: string;
  status?: AsyncTitrationAsyncTitrationStatus;
  patientId?: string;
  rxNorm?: RxNorm;
  initialRxcui?: string;
  initialDoseQuantity?: number;
  initialFrequency?: MedicationChangeFrequency;
  initialReviewNoteId?: number;
  initialReviewNoteStatus?: NoteStatus;
  consentRequestNoteId?: number;
  consentRequestNoteStatus?: NoteStatus;
  approvingCareProvider?: CareProviderDetails;
  approvingCareProviderId?: string;
  initialApprovedAt?: Date;
  rejectionReason?: AsyncTitrationMedicationWasNotTitratedReason;
};

export function useTitrationRecommendation(
  medChange: Nullable<MedicationChange>,
  referenceMedicationId: string,
): TitrationRecommendation {
  const { asyncTitrations, suggestedTitration } = useFlags();
  const { data: referenceMed } = useReferenceMedication(
    referenceMedicationId,
    true,
  );

  if (!asyncTitrations && !suggestedTitration) {
    return {};
  }

  return getTitrationRecommendation(
    medChange,
    referenceMed,
    asyncTitrations,
    suggestedTitration,
  );
}

export function getTitrationRecommendation(
  medChange: Nullable<MedicationChange>,
  referenceMed: Maybe<ReferenceMedication>,
  extractAsyncTitration: boolean,
  extractNextTitrationStep: boolean,
) {
  let recommendation: TitrationRecommendation = {};

  if (extractAsyncTitration && medChange?.asyncTitration) {
    recommendation = asyncTitrationToTitrationRecommendation(
      medChange.asyncTitration,
    );
  } else if (extractNextTitrationStep && medChange?.nextTitrationStep) {
    recommendation = nextTitrationStepToTitrationRecommendation(
      medChange.nextTitrationStep,
    );
  }

  const recommendedRxNorm = referenceMed?.rxNorms?.find(
    (rxNorm) => rxNorm.rxcui === recommendation.rxcui,
  );

  return {
    ...recommendation,
    rxNorm: recommendedRxNorm,
  };
}

function asyncTitrationToTitrationRecommendation(
  asyncTitration: AsyncTitration,
): TitrationRecommendation {
  const approvalDate = asyncTitration.initialReviewAt
    ? parseISO(asyncTitration.initialReviewAt)
    : null;

  return {
    id: asyncTitration.id,
    rxcui: asyncTitration.editedRxcui ?? asyncTitration.nextRxcui,
    doseQuantity:
      asyncTitration.editedDoseQuantity ?? asyncTitration.nextDoseQuantity,
    frequency: asyncTitration.editedFrequency ?? asyncTitration.nextFrequency,
    initialRxcui: asyncTitration.nextRxcui,
    initialDoseQuantity: asyncTitration.nextDoseQuantity,
    initialFrequency: asyncTitration.nextFrequency,
    vitalsCriteria: asyncTitration.nextVitalsCriteria,
    labsCriteria: asyncTitration.nextLabsCriteria,
    symptomsCriteria: asyncTitration.nextSymptomsCriteria,
    status: asyncTitration.status,
    patientId: asyncTitration.inputPatientId,
    initialReviewNoteId: asyncTitration.initialReviewNoteId,
    initialReviewNoteStatus: asyncTitration.initialReviewNoteStatus,
    consentRequestNoteId: asyncTitration.consentRequestNoteId,
    consentRequestNoteStatus: asyncTitration.consentRequestNoteStatus,
    approvingCareProvider: asyncTitration.approvingCareProvider,
    approvingCareProviderId: asyncTitration.initialReviewCareProviderId,
    ...(approvalDate &&
      isValid(approvalDate) && {
        initialApprovedAt: approvalDate,
      }),
    rejectionReason: asyncTitration.medicationWasNotTitratedReason,
  };
}

function nextTitrationStepToTitrationRecommendation(
  nextTitrationStep: NextTitrationStep,
): TitrationRecommendation {
  return {
    rxcui: nextTitrationStep.nextRxcui,
    doseQuantity: nextTitrationStep.nextDoseQuantity,
    frequency: nextTitrationStep.nextFrequency,
    vitalsCriteria: nextTitrationStep.nextVitalsCriteria,
    labsCriteria: nextTitrationStep.nextLabsCriteria,
    symptomsCriteria: nextTitrationStep.nextSymptomsCriteria,
    patientId: nextTitrationStep.patientId,
    // always treat "next titration step" as a new titration
    // recommendation, since it is stateless but the UI inspects
    // the state in a few places.
    status: AsyncTitrationAsyncTitrationStatus.NEW,
  };
}
