import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { LabelledCheckbox } from '@/shared/common/LabelledCheckbox';
import { usePrevious } from '@/shared/hooks';
import { usePatchPatient } from '@/shared/hooks/queries';
import type { Patient } from '@/shared/types/patient.types';
import type { PatientNotificationSettings } from '@/shared/types/shared.types';
import type { TimePeriod } from '@/shared/utils/time-helpers';
import { map12HTimeFormatTo24H } from '@/shared/utils/time-helpers';

import { VitalRemindersTimeEdit } from './VitalRemindersTimeEdit';

export type Props = {
  patient: Patient;
  patientNotifications: PatientNotificationSettings;
  customClass?: string;
};

export function VitalReminders({
  patient,
  patientNotifications,
  customClass,
}: Props) {
  const patchPatient = usePatchPatient(patient.id || '');

  const [isChecked, setIsChecked] = useState(
    patientNotifications.vitals_reminder,
  );
  const previousIsChecked = usePrevious(isChecked);

  useEffect(() => {
    setIsChecked(Boolean(patientNotifications?.vitals_reminder));
  }, [patientNotifications]);

  const handleVitalRemindersToggle = (checked: boolean) => {
    setIsChecked(checked);
    if (!checked && checked !== previousIsChecked) {
      patchPatient.mutate(
        {
          notification_settings: {
            ...patientNotifications,
            vitals_reminder: checked,
          },
        },
        {
          onError: () => {
            // roll back the optimistic update
            setIsChecked(!checked);
          },
        },
      );
    }
  };

  const handleVitalRemindersSave = (hour: string, ampm: TimePeriod) => {
    patchPatient.mutate({
      notification_settings: {
        ...patientNotifications,
        vitals_reminder: isChecked,
      },
      vitals_reminders_time_local: map12HTimeFormatTo24H(hour, ampm),
    });
  };

  return (
    <>
      <LabelledCheckbox
        label={<FormattedMessage defaultMessage="SMS vital reminders" />}
        customClassName={customClass}
        id="vitals-reminders-checkbox"
        checked={isChecked ?? false}
        disabled={patchPatient.isLoading}
        onChange={(e) => handleVitalRemindersToggle(e.target.checked)}
        fontSize="16px"
        customStyles={{
          width: 'fit-content',
          marginLeft: '-6px',
        }}
        customLabelStyles={{ fontWeight: 400 }}
      />
      {isChecked && (
        <VitalRemindersTimeEdit
          loading={patchPatient.isLoading}
          vitalRemindersOn={patientNotifications?.vitals_reminder ?? false}
          time={patient.vitals_reminders_time_local || null}
          onSave={handleVitalRemindersSave}
        />
      )}
    </>
  );
}
