import { useShowVitalsEducation } from '@/shared/common/Wizard/state/sectionUtils';

import { SharedFeedback } from '../../shared/ProductSupport/SharedFeedback';
import { initVisitPath } from '../paths';

export function Feedback() {
  const showVitalsEducation = useShowVitalsEducation();

  return (
    <SharedFeedback
      currentPath={initVisitPath('/product-support', '/feedback')}
      nextPath={
        showVitalsEducation
          ? initVisitPath('/vital-progress', '/intro')
          : initVisitPath('/product-support', '/device-usage')
      }
    />
  );
}
