import type { IntlShape } from 'react-intl';
import { useIntl } from 'react-intl';

import { getTitrationRecommendation } from '@/components/AsyncTitration/hooks';
import { useRejectionReasonI18n } from '@/components/AsyncTitration/hooks/useRejectionReasonI18n';
import { usePatientMedications } from '@/pages/patients/PatientMedications/patientMedications.queries';
import { useReferenceMedications } from '@/pages/patients/PatientMedications/referenceMedications.queries';
import { MarkdownBuilder } from '@/pages/patients/PatientProfile/CNNotesSidebarPanel/templates/engine/MarkdownBuilder';
import type {
  MedicationChange,
  AsyncTitrationMedicationWasNotTitratedReason as Reason,
  ReferenceMedication,
} from '@/shared/generated/grpcGateway/medication.pb';
import { AsyncTitrationAsyncTitrationStatus as AsyncTitrationStatus } from '@/shared/generated/grpcGateway/medication.pb';
import { usePatientVitalsContext } from '@/shared/hooks/queries/vitalsContext.queries';

import { TEMPLATE_HELPERS } from '../../../NoteEditor/templates/engine';
import { TypeOfEncounter } from '../../../Notes.types';
import { getMedicationStrings } from './getMedicationStrings';
import { getSortedRelevantMedChanges } from './getSortedRelevantMedChanges';
import type { AsyncTitrationEncounterType } from './types';

export function shouldShowAsyncTitration(
  encounterType?: TypeOfEncounter,
): encounterType is AsyncTitrationEncounterType {
  if (!encounterType) {
    return false;
  }

  return [
    TypeOfEncounter.ASYNC_REVIEW,
    TypeOfEncounter.TITRATION_OUTREACH,
  ].includes(encounterType);
}

export function useGetAsyncTitrationNoteBody(
  patientId: string,
  noteId: Maybe<number>,
) {
  const intl = useIntl();
  const rejectionReasonsI18n = useRejectionReasonI18n();
  const { data: patientMeds } = usePatientMedications(patientId);
  const { data: patientVitals } = usePatientVitalsContext(patientId);
  const { data: referenceMeds } = useReferenceMedications();

  if (!patientMeds || !patientVitals || !referenceMeds || !noteId) {
    return () => null;
  }

  return (
    encounterType: AsyncTitrationEncounterType,
    medReviewBody: Nullable<string>,
  ) => {
    if (!medReviewBody) {
      return null;
    }

    const changesWithAsyncTitration = getSortedRelevantMedChanges(
      encounterType,
      noteId,
      patientMeds.referencedMedications,
      referenceMeds.referenceMedications,
    );

    if (!changesWithAsyncTitration.length) {
      return null;
    }

    const md = new MarkdownBuilder();
    const systolicAvg =
      patientVitals.contextVitals?.bloodPressure?.systolic?.avg30d;
    const diastolicAvg =
      patientVitals.contextVitals?.bloodPressure?.diastolic?.avg30d;
    const bpString = TEMPLATE_HELPERS.bp(systolicAvg, diastolicAvg);

    if (encounterType === TypeOfEncounter.ASYNC_REVIEW) {
      md.p(
        `Chart reviewed and 30 day BP average is ${bpString}. Patient currently on the following medication(s):`,
      );
    } else if (encounterType === TypeOfEncounter.TITRATION_OUTREACH) {
      md.p('Spoke with patient and reviewed most recent BP trends:');
      md.uli(`30 day average blood pressure: ${bpString}`);
      md.uli('Verified medications in Cadence chart as accurate.');
      md.newline();
    }

    md.newline();
    md.concat(medReviewBody);
    md.newline();

    changesWithAsyncTitration.forEach(([referenceMed, change]) => {
      switch (change.asyncTitration?.status) {
        case AsyncTitrationStatus.INITIALLY_REVIEWED:
          appendInitiallyApprovedTitrationBody(md, intl, change, referenceMed);
          break;
        case AsyncTitrationStatus.REJECTED_ON_INITIAL_REVIEW:
          appendInitiallyRejectedTitrationBody(
            md,
            change,
            referenceMed,
            rejectionReasonsI18n,
          );
          break;
        case AsyncTitrationStatus.PATIENT_CONSENTED:
          appendPatientConsentLanguage(md, intl, change, referenceMed);
          appendPatientConsentedTitrationBody(md, referenceMed);
          break;
        case AsyncTitrationStatus.PATIENT_REJECTED:
          appendPatientConsentLanguage(md, intl, change, referenceMed);
          appendPatientRejectedTitrationBody(md);
          break;
        default:
        // do nothing
      }
    });

    md.newline();

    if (encounterType === TypeOfEncounter.ASYNC_REVIEW) {
      md.p(
        'Patient to follow up with Care Team at their next scheduled appointment and will be advised to contact us for any questions or concerns.',
      );
    } else if (encounterType === TypeOfEncounter.TITRATION_OUTREACH) {
      md.p(
        'Advised patient to call us back if they have any questions or concerns and that we have nursing staff available 24 hours a day/7 days a week.',
      );
    }

    return md.toString();
  };
}

function appendInitiallyApprovedTitrationBody(
  md: MarkdownBuilder,
  intl: IntlShape,
  med: MedicationChange,
  referenceMed: ReferenceMedication,
) {
  const { isValid, medicationName, dosage, frequencyText } =
    getMedicationStrings(intl, med, referenceMed);

  if (!isValid) {
    return;
  }

  md.p(
    `Review of chart shows patient has tolerated ${medicationName} well and recent laboratory ` +
      'test results, as indicated by Cadence Protocols, reveal no contraindications. Recommend ' +
      `increasing ${medicationName} to ${dosage} ${frequencyText}. ` +
      'Patient will be notified of recommended titration and possible side effects.',
  );

  if (referenceMed.requiredLabs) {
    md.p(`This medication needs labs to be drawn ${referenceMed.requiredLabs}`);
  }
}

function appendPatientConsentLanguage(
  md: MarkdownBuilder,
  intl: IntlShape,
  med: MedicationChange,
  referenceMed: ReferenceMedication,
) {
  const { isValid, clinicianName, medicationName, dosage, frequencyText } =
    getMedicationStrings(intl, med, referenceMed);

  if (!isValid) {
    return;
  }

  md.p(
    `Per ${clinicianName}, NP advised patient to increase ${medicationName} to ${dosage} ${frequencyText}.`,
  );
}

function appendInitiallyRejectedTitrationBody(
  md: MarkdownBuilder,
  med: MedicationChange,
  referenceMed: ReferenceMedication,
  rejectionReasonI18n: Record<Reason, string>,
) {
  const { rejectionReason } = getTitrationRecommendation(
    med,
    referenceMed,
    true,
    false,
  );

  md.p(
    `We will not titrate ${referenceMed.name?.toUpperCase()}. Reason: ${
      rejectionReasonI18n[rejectionReason as Reason]
    }.`,
  );
}

function appendPatientConsentedTitrationBody(
  md: MarkdownBuilder,
  referenceMed: ReferenceMedication,
) {
  md.p('Patient agrees to plan.');

  if (referenceMed.sideEffects) {
    md.p(
      `Discussed possible side effects that could include: ${referenceMed.sideEffects}.`,
    );
  }

  if (referenceMed.requiredLabs) {
    md.p(
      `This medication needs labs to be drawn ${referenceMed.requiredLabs}.`,
    );
  }
}

function appendPatientRejectedTitrationBody(md: MarkdownBuilder) {
  md.p('Patient does not agree to medication change at this time.');
}
