import cx from 'classnames';
import { useRef } from 'react';
import { FocusRing, useOption } from 'react-aria';
import type { ListState, Node, SelectionMode } from 'react-stately';

import { Checkbox } from '@/shared/tempo/atom/Checkbox';
import {
  option,
  optionFocusOutline,
} from '@/shared/tempo/shared/styles/listStyles.css';
import { focusRingCss } from '@/shared/tempo/util/FocusRing/FocusRing.css';

type Props<T> = {
  item: Node<T>;
  state: ListState<T>;
  className?: string;
  selectionMode?: SelectionMode;
};

export function Option<T>({ item, state, className, selectionMode }: Props<T>) {
  const ref = useRef(null);
  const {
    optionProps,
    isFocused: isHovered,
    isSelected,
    isDisabled,
  } = useOption({ key: item.key }, state, ref);

  return (
    <FocusRing focusRingClass={cx(focusRingCss.keyboard, optionFocusOutline)}>
      <li
        {...optionProps}
        ref={ref}
        className={cx(className, {
          [option.default]: !isSelected && !isHovered,
          [option.selected]: isSelected,
          [option.hover]: isHovered,
          [option.disabled]: isDisabled,
        })}
      >
        {selectionMode === 'multiple' && (
          <Checkbox
            isFocusDisabled
            isSelected={isSelected}
            isDisabled={isDisabled}
          />
        )}
        <span aria-disabled={isDisabled}>{item.rendered}</span>
      </li>
    </FocusRing>
  );
}
