import type { IntlShape } from 'react-intl';
import * as yup from 'yup';

import type { FormConfig } from '@/shared/common/Form';
import { validators } from '@/shared/common/Form/validations';

export type FormFields = {
  approvalDate?: Date;
};

export function getFormConfig(intl: IntlShape): FormConfig {
  const { required, date } = validators(intl);

  return {
    fields: {
      approvalDate: {
        defaultValue: null,
        validation: required(date()),
      },
      approvalTime: {
        defaultValue: null,
        validation: required(yup.string()),
      },
    },
  };
}
