import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import {
  FieldGroup,
  Script,
} from '@/pages/patients/PatientProfile/CNNotesSidebarPanel/shared';
import { useTimeEstimate } from '@/pages/patients/PatientProfile/CNNotesSidebarPanel/shared/useTimeEstimate';
import { Wizard } from '@/shared/common/Wizard';
import { useShowVitalsEducation } from '@/shared/common/Wizard/state/sectionUtils';
import { display } from '@/shared/jsStyle/utils.css';

import { REGULAR_VISIT_SECTIONS } from '../../metadata';
import { ActionPlanIntroScript } from '../../shared/actionPlan/ActionPlanIntroScript';
import { regVisitPath } from '../paths';

export function NoPrevGoal() {
  const intl = useIntl();
  const history = useHistory();

  const showVitalsEducation = useShowVitalsEducation();

  return (
    <Wizard.Step
      sections={REGULAR_VISIT_SECTIONS}
      title={intl.formatMessage({ defaultMessage: 'Action plan' })}
      subtitle={useTimeEstimate(
        intl.formatMessage({ defaultMessage: '1-3 min' }),
      )}
      onNext={() => history.push(regVisitPath('/action-plan', '/set-goal'))}
    >
      <FieldGroup>
        {showVitalsEducation ? (
          <ActionPlanIntroScript />
        ) : (
          <>
            <Script className={display.block}>
              <FormattedMessage defaultMessage="Thanks for reviewing all of that information with me. It helps to know if anything has changed since the last time we spoke. Our next step in today's visit is to choose a health goal and outline the steps you need to take to achieve that health goal." />
            </Script>
            <Script className={display.block}>
              <FormattedMessage defaultMessage="The idea behind doing this is instead of me choosing a goal for you, you choose one that is meaningful and relevant to you, and hopefully that makes it easier to accomplish." />
            </Script>
          </>
        )}
      </FieldGroup>
    </Wizard.Step>
  );
}
