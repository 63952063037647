import { formatISO, parseISO, startOfDay } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import type { IntlShape } from 'react-intl';
import * as yup from 'yup';

import { validators } from '@/shared/common/Form/validations';
import {
  ProgramProgramStatus,
  ProgramProgramType,
} from '@/shared/generated/grpcGateway/pms.pb';
import type { NPI } from '@/shared/types/npi.types';
import { type Patient, PatientStatus } from '@/shared/types/patient.types';
import { MedManagementDelegation } from '@/shared/types/shared.types';
import { getUserTimezone } from '@/shared/utils/time-helpers';

export type ProgramConsentAndInfoFields = {
  rpm_consent_date?: Date;
  ccm_consent_date?: Date;
  npi: NPI;
  med_management_delegation: MedManagementDelegation;
};

export function getProgramConsentAndInfoFields(
  intl: IntlShape,
  maxDate: Date,
  patient?: Patient,
  npi?: NPI,
) {
  const { date, required, enumType } = validators(intl);
  const consentDateValidator = date({
    maxDate,
    maxDateErrorMessage: intl.formatMessage({
      defaultMessage: 'Must be today or a valid date in the past',
    }),
  });

  const isEnrolled = patient?.status === PatientStatus.Enrolled;
  const hasRpmProgram = patient?.programs?.some(
    (program) =>
      program.program_status &&
      [ProgramProgramStatus.ENROLLED, ProgramProgramStatus.SELECTED].includes(
        program.program_status,
      ) &&
      program.program_type === ProgramProgramType.RPM,
  );
  const hasCcmProgram = patient?.programs?.some(
    (program) =>
      program.program_status &&
      [ProgramProgramStatus.ENROLLED, ProgramProgramStatus.SELECTED].includes(
        program.program_status,
      ) &&
      program.program_type === ProgramProgramType.CCM,
  );

  const rpmConsentDate = patient?.rpm_consent_date
    ? parseISO(patient.rpm_consent_date)
    : null;
  const ccmConsentDate = patient?.ccm_consent_date
    ? parseISO(patient.ccm_consent_date)
    : null;

  const consentTimezone = patient?.timezone || getUserTimezone();
  const localizeToPatientTz = (consentDate: Date) =>
    formatInTimeZone(consentDate, consentTimezone, 'MM/dd/yyyy');

  return {
    rpm_consent_date: {
      defaultValue: rpmConsentDate ? localizeToPatientTz(rpmConsentDate) : null,
      validation:
        hasRpmProgram && isEnrolled
          ? required(consentDateValidator)
          : consentDateValidator,
    },
    ccm_consent_date: {
      defaultValue: ccmConsentDate ? localizeToPatientTz(ccmConsentDate) : null,
      validation:
        hasCcmProgram && isEnrolled
          ? required(consentDateValidator)
          : consentDateValidator,
    },
    npi: {
      defaultValue: npi || null,
      validation: required(
        yup.object({
          id: yup.number().defined(),
          npi: yup.string().defined(),
        }),
      )
        .typeError(
          intl.formatMessage({ defaultMessage: 'NPI must be a number' }),
        )
        // Value is still required, but this is here so it won't fail type check if null. This way if the value is null, we show the required error message rather than the type error message
        .nullable(),
    },
    med_management_delegation: {
      defaultValue:
        patient?.med_management_delegation ||
        MedManagementDelegation.OptInFullyManaged,
      // If it's visible then it's required but there is no way to select
      // a missing nor invalid value so required validation isn't needed here
      validation: enumType({
        source: MedManagementDelegation,
        pluck: 'values',
      }),
    },
  };
}

export function serializeProgramInfoFormValues(
  values: ProgramConsentAndInfoFields,
) {
  return {
    rpm_consent_date: values.rpm_consent_date
      ? formatISO(startOfDay(values.rpm_consent_date))
      : null,
    ccm_consent_date: values.ccm_consent_date
      ? formatISO(values.ccm_consent_date)
      : null,
    npi_id: parseInt(String(values.npi.id), 10),
    ...(values.med_management_delegation && {
      med_management_delegation: values.med_management_delegation,
    }),
  };
}
