import useResizeObserver from '@react-hook/resize-observer';
import type { RefObject } from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';

import { hidden } from '@/shared/jsStyle/utils.css';

/**
 * Based on the dimensions of the container ref, and the rendered tag widths.
 * This hook will hide the tags that overflow outside the width of the container
 */
export function useHideOverflowTags(
  containerRef: RefObject<HTMLDivElement>,
  selectButtonRef: RefObject<HTMLButtonElement>,
) {
  const [hiddenTagCount, setHiddenTagCount] = useState(0);
  const mutationObserver = useRef<MutationObserver>();
  const truncateTags = useCallback(() => {
    if (!containerRef.current) {
      return;
    }

    const container = containerRef.current;
    const getContainerWidth = () =>
      Math.ceil(container.getBoundingClientRect().width);
    const tags = container.children;
    const gap = parseInt(getComputedStyle(container).columnGap, 10);

    // prefill the gaps (if any) between the tags so that we don't
    // need to account for them when calculating the width of the tags
    let tagWidths = 0;
    let hiddenTags = 0;

    for (let i = 0; i < tags.length; i++) {
      const firstTag = i === 0;
      const tag = tags[i];
      tag.classList.remove(hidden);
      tagWidths += tag.clientWidth;

      const currentGapWidth = i * gap;

      // Always show at least one tag by skipping hiding the first tag
      // Call getContainerWidth in-line to ensure we have most up-to-date width
      if (!firstTag && tagWidths + currentGapWidth > getContainerWidth()) {
        tag.classList.add(hidden);
        hiddenTags += 1;
      }
    }

    if (hiddenTags !== hiddenTagCount) {
      setHiddenTagCount(hiddenTags);
    }
  }, [containerRef, hiddenTagCount]);

  // truncate tags when the tag container size changes
  useResizeObserver(containerRef, truncateTags);

  // truncate tags when the select's parent element size grows
  // The containerRef won't grow on resize when using fit-content width strategy
  useResizeObserver(
    selectButtonRef.current?.parentElement || null,
    truncateTags,
  );

  // truncate tags when a node is added or removed
  useEffect(() => {
    mutationObserver.current?.disconnect();

    if (containerRef.current) {
      mutationObserver.current = new MutationObserver(truncateTags);
      mutationObserver.current.observe(containerRef.current, {
        childList: true,
      });
    }

    return () => mutationObserver.current?.disconnect();
  }, [containerRef, truncateTags]);

  return { hiddenTagCount };
}
