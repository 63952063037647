import { FormattedMessage, useIntl } from 'react-intl';

import {
  deliveryMechanismLabel,
  frequencyLabel,
  getRxNormStr,
} from '@/pages/patients/PatientMedications/utils/medInfoUtils';
import GanttIcon from '@/shared/assets/svgs/gantt.svg?react';
import type { RxNorm } from '@/shared/generated/grpcGateway/medication.pb';
import { Tag } from '@/shared/tempo/atom/Tag';
import { color } from '@/shared/tempo/theme';

import type { TitrationRecommendation } from '../hooks';
import { protocols, tag } from './SuggestedTitration.css';

type Props = {
  titrationRecommendation: TitrationRecommendation;
  recommendedRxNorm: RxNorm;
};

export function Protocols({
  titrationRecommendation,
  recommendedRxNorm,
}: Props) {
  const intl = useIntl();

  return (
    <>
      <div className={protocols.header}>
        <div className={protocols.title}>
          <GanttIcon
            stroke={color.Theme.Light['Base Font Subtle']}
            fill={color.Theme.Light['Base Font Subtle']}
          />
          <span>
            <FormattedMessage defaultMessage="Protocols" />
          </span>
        </div>
        <span className={protocols.disclaimer}>
          <FormattedMessage defaultMessage="Reference material below is from the Cadence Titration Protocols and is subject to your independent review for accuracy and appropriateness. Ensure that all laboratory and hemodynamic parameters are reviewed and all titration criteria are met before adjusting medication." />
        </span>
      </div>
      <span className={protocols.guide}>
        <FormattedMessage defaultMessage="If" />
      </span>
      <table className={protocols.table}>
        <tbody>
          <tr>
            <th className={protocols.th}>
              <FormattedMessage defaultMessage="Vitals" />
            </th>
            <td className={protocols.td}>
              {titrationRecommendation.vitalsCriteria}
            </td>
          </tr>
          <tr>
            <th className={protocols.th}>
              <FormattedMessage defaultMessage="Symptoms" />
            </th>
            <td className={protocols.td}>
              {titrationRecommendation.symptomsCriteria}
            </td>
          </tr>
          <tr>
            <th className={protocols.th}>
              <FormattedMessage defaultMessage="Labs" />
            </th>
            <td className={protocols.td}>
              {titrationRecommendation.labsCriteria}
            </td>
          </tr>
        </tbody>
      </table>
      <span className={protocols.guide}>
        <FormattedMessage defaultMessage="Increase to" />
      </span>
      <div className={protocols.recommendation}>
        <Tag variant="info" className={tag.recommendation}>
          {getRxNormStr(recommendedRxNorm, intl)}
        </Tag>
        <Tag variant="info" className={tag.recommendation}>
          <FormattedMessage
            defaultMessage="{amount} {deliveryMechanism}"
            values={{
              amount: titrationRecommendation.doseQuantity,
              deliveryMechanism: deliveryMechanismLabel(
                intl,
                recommendedRxNorm.deliveryMechanism,
              ),
            }}
          />
        </Tag>
        <Tag variant="info" className={tag.recommendation}>
          {frequencyLabel(intl, titrationRecommendation.frequency)}
        </Tag>
      </div>
    </>
  );
}
